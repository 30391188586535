
import api from "../config/api";

export const updateCredentialsCompanies = async ({token, credentialSelected, values, action}) => {
    const response = await api.put(`api/client/access/credentials/${credentialSelected.credential_id}/companies`, {
        companies: values,
        action: action,
    }, {
        headers: {'Access-Token': token},
    }).catch(err => {
        throw new Error(err.response.data.message[0]?.text);
    });
    return response.data;
}