import React, {useState} from 'react'
import {useDispatch, useSelector} from "react-redux"
import {deleteCredential} from "../../../../../redux/credentialsSlice";
import {useHistory} from "react-router";
import StatusWrapper from "../../../../../abstractComponents/StatusWrapper";

const RemoveCredentialComponent = ({credential}) => {
    const dispatch = useDispatch()

    const [status, setStatus] = useState(null)

    const authToken = useSelector((state) => state.auth.token)
    const credentialStatus = useSelector((state) => state.credentials.status)

    let history = useHistory();

    const confirmDelete = async () => {
        await dispatch(deleteCredential({
            token: authToken,
            credentialId: credential.credential_id
        })).then(() => history.push('/access/credentials/'))
        setStatus(null)
    }

    if (status === 'confirm') {
        return <StatusWrapper statusSelector={credentialStatus}>
            <div
                className="alert alert-danger m-3 d-flex flex-column justify-content-between text-center align-items-center"
                role="alert">
                <i className="fas fa-exclamation-triangle fs-1"/>
                <div>
                    <h4 className="alert-heading">Are you sure?</h4>
                    <p>This action can't be undoned!</p>
                    <button className={"btn btn-outline-danger me-3"} type={'button'}
                            onClick={() => confirmDelete()}><i className={"far fa-trash-alt"}/> Remove forever with all
                        they data
                    </button>
                </div>
            </div>
        </StatusWrapper>
    }
    return (
        <div className="alert alert-danger m-3" role="alert">
            <h4 className="alert-heading"><i className="fas fa-skull-crossbones me-2"/>Remove credential</h4>
            <p>Can't be undoned! It will remove:</p>
            <ul>
                <li>User data on DB</li>
                <li>User data on AWS Cognito</li>
                <li>Files on S3 Bucket</li>
            </ul>
            <button className={"btn btn-outline-danger me-3"} type={'button'}
                    onClick={() => setStatus('confirm')}>Execute action
            </button>
        </div>
    )
}
export default RemoveCredentialComponent