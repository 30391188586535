import React from 'react'
import {useSelector} from "react-redux";

import {fetchCredentials, selectAllCredentials} from "../../../redux/credentialsSlice";
import TableComponent from "../../../abstractComponents/TableComponent";
import {Link, NavLink} from "react-router-dom";
import {useRouteMatch} from "react-router";
import * as Notify from "../../../layout/Notify";
import * as moment from "moment";
import {hashCode, intToRGB} from "../../../helpers/intTools";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const showGroupsModal = (credential) => {
    let groups = credential.groups.map(e => e.name).join(",");
    return Notify.Alert.fire({text: `Groups: ${groups}`})
}
const CredentialRow = ({credential, index}) => {
    let match = useRouteMatch();

    return <tr key={index} className={(credential.deleted_at !== null ? 'table-danger' : '')}>
        <td style={{color: `#${intToRGB(hashCode(credential.external_iss))}`}} title={credential.external_iss}>
            <FontAwesomeIcon icon="seedling"/>
        </td>
        <td>{credential.credential_id}</td>
        <td>
            <div className={'rounded border border-secondary overflow-hidden'} style={{
                width: '30px',
                height: '30px',
                display: 'flex',
                alignItems: 'end',
                justifyContent: 'center',
                backgroundColor: 'darkgray',
                color: 'white',
                fontSize: '1.4em',
            }}>{credential.avatar_url ?
                <img src={credential.avatar_url} alt={'Avatar'} style={{maxWidth: '100%', maxHeight: '100%'}}/>
                :
                <FontAwesomeIcon icon="user"/>
            }</div>
        </td>
        <td>{credential.username}</td>
        <td>{credential.name}</td>
        <td>{credential.surname}</td>
        <td>{credential.companies && credential.companies.length > 0 ? credential.companies.map((company, i) => (
            <Link key={i}
                  to={'/access/companies/update/' + company.company_id}
                  style={{color: `#${intToRGB(hashCode(company.name))}`}}>
                <FontAwesomeIcon
                    icon="building"
                    className="me-1"
                    title={company.name}/>
            </Link>
        )) : '-'}</td>
        <td>{credential.created_at ? moment(credential.created_at).format('L') : '-'}</td>
        <td>{credential.last_login ? moment(credential.last_login).format('L') : '-'}</td>
        <td>
            <NavLink to={`${match.path}update/${credential.credential_id}`} className="btn btn-info btn-sm me-2"><i
                className="far fa-edit"/>Edit</NavLink>
            {credential.groups.length > 0 ?
                <button className={'btn btn-info btn-sm'} onClick={() => showGroupsModal(credential)}>
                    <i className="fas fa-users"/> Groups
                </button> : null}
        </td>
    </tr>
}

const CredentialsList = () => {
    const credentials = useSelector(selectAllCredentials)
    const credentialsStatus = useSelector(state => state.credentials.status)
    const columns = ['', '', '', 'Username', 'Name', 'Surname', 'Companies', 'Created at', 'Last login', '']

    return <div className={'card'}>
        <TableComponent columns={columns}
                        data={credentials}
                        fetchData={fetchCredentials}
                        status={credentialsStatus}
                        rowComponent={(row, key) => <CredentialRow key={key} credential={row}/>}/>
    </div>
}
export default CredentialsList