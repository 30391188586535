import React from 'react'
import RemoveCredentialComponent from "./actions/RemoveCredentialComponent";
import ChangePasswordComponent from "./actions/ChangePasswordComponent";
import ResetPasswordComponent from "./actions/ResetPasswordComponent";
import {useSelector} from "react-redux";
import StatusWrapper from "../../../../abstractComponents/StatusWrapper";

const TabUpdateFormComponent = ({credential}) => {
    const credentialStatus = useSelector((state) => state.credentials.status)

    return <StatusWrapper statusSelector={credentialStatus}>
        <ChangePasswordComponent credential={credential}/>
        <ResetPasswordComponent credential={credential}/>
        <RemoveCredentialComponent credential={credential}/>
    </StatusWrapper>
}
export default TabUpdateFormComponent