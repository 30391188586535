import React from 'react'
import {useDispatch, useSelector} from "react-redux";

import {deleteCredential, fetchCredentials, selectAllCredentials} from "../../../redux/goliathCredentialsSlice";
import TableComponent from "../../../abstractComponents/TableComponent";
import {NavLink} from "react-router-dom";
import {useRouteMatch} from "react-router";
import * as Notify from "../../../layout/Notify";
import * as moment from "moment";

const showGroupsModal = (credential) => {
    let groups = credential.groups.map(e => e.name).join(",");
    return Notify.Alert.fire({text: `Groups: ${groups}`})
}
const CredentialRow = ({credential, index}) => {
    let match = useRouteMatch();

    const dispatch = useDispatch()
    const authToken = useSelector((state) => state.auth.token)
    const deleteAction = async () => dispatch(deleteCredential({
        token: authToken,
        credentialId: credential.credential_id
    }))

    return <tr key={index} className={(credential.deleted_at !== null ? 'table-danger' : '')}>
        <td>{credential.credential_id}</td>
        <td>{credential.avatar_url ?
            <img src={credential.avatar_url} className={'rounded'} alt={'Avatar'}
                 style={{maxWidth: '30px', maxHeight: '30px'}}/> : '-'}</td>
        <td>{credential.name}</td>
        <td>{credential.surname}</td>
        <td>{credential.username}</td>
        <td>{credential.created_at ? moment(credential.created_at).format('L') : '-'}</td>
        <td>{credential.updated_at ? moment(credential.updated_at).format('L') : '-'}</td>
        <td>{credential.deleted_at ? moment(credential.deleted_at).format('L') : '-'}</td>
        <td>
            <NavLink to={`${match.path}update/${credential.credential_id}`} className="btn btn-info btn-sm me-2"><i
                className="far fa-edit"/>Edit</NavLink>
            {credential.groups.length > 0 ?
                <button className={'btn btn-info btn-sm'} onClick={() => showGroupsModal(credential)}>
                    <i className="fas fa-users"/> Groups
                </button> : null}
        </td>
    </tr>
}

const CredentialsList = () => {
    const credentials = useSelector(selectAllCredentials)
    const credentialsStatus = useSelector(state => state.credentials.status)
    const columns = ['', 'Avatar', 'Name', 'Surname', 'Username', 'Created at', 'Updated at', 'Deleted at', '']

    return <div className={'card'}>
        <TableComponent columns={columns}
                        data={credentials}
                        fetchData={fetchCredentials}
                        status={credentialsStatus}
                        rowComponent={(row, key) => <CredentialRow key={key} credential={row}/>}/>
    </div>
}
export default CredentialsList