import React from 'react'

import AddCompanyForm from './AddCompanyForm'
import ShowCompaniesTable from "./CompaniesList";
import {Route, Switch} from "react-router";
import {NavLink} from "react-router-dom";
import CompanyUpdateContainer from "./update/CompanyUpdateContainer";

export default ({match}) => {

    return <div>
        <div className="row flex-xl-nowrap">
            <div className="col-md-3 col-xl-2 py-md-3 pl-md-5">
                <div className={'card p-2'}>
                    <nav className="nav nav-pills nav-fill flex-column">
                        <NavLink to={`${match.path}/`} exact className="nav-link"
                                 activeClassName={'active'}>List</NavLink>
                        <NavLink to={`${match.path}/create`} className="nav-link" activeClassName={'active'}>Create
                            new</NavLink>
                    </nav>
                </div>
            </div>
            <main className="col-md-9 col-xl-10 py-md-3 pl-md-5" role="main">
                <Switch>
                    <Route path={`${match.path}/create`} exact>
                        <AddCompanyForm/>
                    </Route>
                    <Route path={`${match.path}/update/:companyId`} exact component={CompanyUpdateContainer}/>
                    <Route path={`${match.path}/`} exact component={ShowCompaniesTable}/>
                </Switch>
            </main>
        </div>
    </div>
}