import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import api from "../config/api";

export const fetchGroups = createAsyncThunk('groups/fetchGroups', async ({token, page = null}) => {
    const response = await api.get(`api/client/access/groups`, {
        headers: {'Access-Token': token},
    }).catch(err => {
        throw new Error(err.response.data.message[0]?.text);
    });
    return response.data;
})
export const updateCredentialGroup = createAsyncThunk('groups/updateCredentialGroup', async ({token, credential, values, action}) => {
    const response = await api.post(`api/client/access/groups/join`, {
        credential: credential,
        groups: values,
        action: action,
    }, {
        headers: {'Access-Token': token},
    }).catch(err => {
        throw new Error(err.response.data.message[0]?.text);
    });
    return response.data;
})
const groupsSlice = createSlice({
    name: 'groups',
    initialState: {
        groups: [],
        status: {
            type: 'idle',
            error: null,
        },
    },
    reducers: {},
    extraReducers: {
        [fetchGroups.pending]: (state, action) => {
            state.status.type = 'loading'
        },
        [fetchGroups.fulfilled]: (state, action) => {
            state.status.type = 'succeeded'
            state.groups = action.payload.data
        },
        [fetchGroups.rejected]: (state, action) => {
            state.status.type = 'failed'
            state.status.error = action.error.message
        },

        [updateCredentialGroup.pending]: (state, action) => {
            state.status.type = 'loading'
        },
        [updateCredentialGroup.fulfilled]: (state, action) => {
            state.status.type = 'succeeded'
        },
        [updateCredentialGroup.rejected]: (state, action) => {
            state.status.type = 'failed'
            state.status.error = action.error.message
        },
    }
})

export const {credentialUpdated} = groupsSlice.actions
export default groupsSlice.reducer

export const selectAllGroups = (state) => state.groups.groups
