import React, {useState} from 'react'
import {useDispatch, useSelector} from "react-redux"
import {deleteCompany, selectSelectedCompany, updateCompany} from "../../../../redux/companiesSlice";
import * as Notify from "../../../../layout/Notify";
import {Field, Form, Formik} from "formik";
import {useHistory} from "react-router";
import LoadingTools from "../../../../layout/LoadingTools";

export default (props) => {
    const {match, company_id} = props
    const [status, setStatus] = useState(null)

    const authToken = useSelector((state) => state.auth.token)
    const companySelected = useSelector(selectSelectedCompany)
    const companyStatus = useSelector((state) => state.companies.status)

    const dispatch = useDispatch()

    const history = useHistory();

    const confirmDelete = async () => Notify.Alert.fire({
        title: 'Are you sure?',
        html: `You're going to delete <code>${companySelected.name}</code>, you won't be able to revert this!`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#5cd630',
        confirmButtonText: 'Yes, delete it!',
        preConfirm: async () => await dispatch(deleteCompany({token: authToken, companyId: company_id})),
        allowOutsideClick: () => !Notify.Alert.isLoading()
    })
        .then(async (result) => {
            if (result.isConfirmed) {
                await Notify.Alert.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                )
                history.push('/access/companies/')
            }
        })
        .catch(async () =>
            await Notify.Alert.fire(
                'Error',
                'Your file has not been deleted.',
                'danger'
            ))

    let printTimeout;
    const PrintStatus = () => {
        if (printTimeout) clearTimeout(printTimeout)
        printTimeout = setTimeout(() => {
            setStatus(null)
        }, 6000)
        switch (status) {
            case 'succeeded':
                return <div className={'ms-3 mt-2 text-success'}><i className="fas fa-check"/> Applied</div>
            case 'loading':
                return <div className={'ms-3 mt-2 text-secondary'}><i className="fas fa-spinner fa-spin"/> Loading...
                </div>
            case 'error':
                return <div className={'ms-3 mt-2 text-danger'}><i className="fas fa-times"/> Error</div>
            default:
                return null
        }
    }

    const onSaveCompanyClicked = async (values) => {
        try {
            setStatus('loading')
            await dispatch(updateCompany({token: authToken, updatedCompany: {...companySelected, ...values}}))
            setStatus('succeeded')
        } catch (err) {
            console.error('Failed to save the credential: ', err)
            setStatus('error')
        }
    }

    let disabled = companyStatus.type === 'loading'
    if (companyStatus.type === 'succeeded' || (companySelected.company_id && companyStatus.type === 'loading')) {
        return (
            <>
                <div className={"d-flex justify-content-between mb-3"}>
                    <h3 className={'card-title'}>Update company</h3>
                    <button className={"btn btn-danger me-3 btn-sm"} type={'button'}
                            onClick={() => confirmDelete()}><i className={"far fa-trash-alt"}/> Remove
                    </button>
                </div>

                <Formik initialValues={companySelected} onSubmit={onSaveCompanyClicked}>
                    <Form>
                        <div className={"form-floating mb-3"}>
                            <Field id={'name'} name={'name'} type={'text'} className={'form-control'}
                                   placeholder={'Name'} disabled={disabled}/>
                            <label htmlFor={'name'}>Name</label>
                        </div>
                        <div className={"mb-3"}>
                            <div className={"input-group mb-3"}>
                                <div className={'form-floating'}>
                                    <Field id={'url'} name={'url'} type={'text'} className={'form-control'}
                                           placeholder={'Url prefix'} disabled={disabled}/>
                                    <label htmlFor={'url'}>Url prefix</label>
                                </div>
                                <span className={"input-group-text"}>.app.winnow.video</span>
                            </div>
                        </div>
                        <div className="form-floating mb-3">
                            <Field id={'limit_indexing'} name={'limit_indexing'} type={'number'}
                                   className={'form-control'} placeholder={'Indexing Limits'}
                                   disabled={disabled}/>
                            <label htmlFor={'limit_indexing'}>Indexing Limits (hours)</label>
                        </div>
                        <div className={'mt-3 d-flex align-content-center'}>
                            <button type={'submit'} className={'btn btn-success'}>Save</button>
                            <PrintStatus/>
                        </div>
                    </Form>
                </Formik>
            </>
        )
    } else if (companyStatus.type === 'loading') {
        return <LoadingTools/>
    } else if (companyStatus.type === 'failed') {
        return <div className={'alert alert-danger'}>{companyStatus.error}</div>
    }
    return <div/>
}