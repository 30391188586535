import React, {useState} from 'react'
import {useDispatch, useSelector} from "react-redux"
import {selectSelectedCompany} from "../../../../redux/companiesSlice";
import {Field, Form, Formik} from "formik";
import {selectAllParameters, selectAllParametersTypes} from "../../../../redux/parametersSlice";
import LoadingTools from "../../../../layout/LoadingTools";

export default (props) => {
    const [status, setStatus] = useState(null)

    const authToken = useSelector((state) => state.auth.token)
    const companySelected = useSelector(selectSelectedCompany)
    const parameters = useSelector(selectAllParameters)
    const parametersTypes = useSelector(selectAllParametersTypes)
    const parametersStatus = useSelector((state) => state.parameters.status)
    const parametersTypesStatus = useSelector((state) => state.parameters.typesStatus)

    const dispatch = useDispatch()

        return (
            <>
                <Formik initialValues={{patata:''}} onSubmit={()=>{}}>
                    <Form>
                        <div className={`row`}>
                            <div className={"col-12 col-md-6 col-xl-3 mb-3"}>
                                <label htmlFor={'patata'}>This is a form control</label>
                                <Field id={'patata'} name={'patata'} type={'text'}
                                       className={`form-control`}
                                       placeholder={'Empty'}/>
                            </div>
                            <div className={"col-12 col-md-6 col-xl-3 mb-3"}>
                                <label htmlFor={'patata'}>This is a form control</label>
                                <Field id={'patata'} name={'patata'} type={'text'}
                                       className={`form-control`}
                                       placeholder={'Empty'}/>
                            </div>
                        </div>
                        <div className={'mt-3 d-flex align-content-center'}>
                            <button type={'submit'} className={'btn btn-success'}>Save</button>
                        </div>
                    </Form>
                </Formik>
            </>
        )
    if (parametersStatus.type === 'succeeded' && parametersTypesStatus.type === 'succeeded') {
    } else if (parametersStatus.type === 'loading' || parametersTypesStatus.type === 'loading') {
        return <LoadingTools/>
    } else if (parametersStatus.type === 'failed') {
        return <div className={'alert alert-danger'}>{parametersStatus.error}</div>
    } else if (parametersTypesStatus.type === 'failed') {
        return <div className={'alert alert-danger'}>{parametersTypesStatus.error}</div>
    }
    return <div/>
}