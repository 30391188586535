import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import api from "../config/api";

export const fetchCompanies = createAsyncThunk('companies/fetchCompanies', async ({token, page = null}) => {
    const response = await api.get(`api/client/access/companies/list/${(page !== null ? page : '')}`, {
        headers: {'Access-Token': token},
    }).catch(err => {
        throw new Error(err.response.data.message[0]?.text);
    });
    return response.data;
})
export const fetchCompany = createAsyncThunk('companies/fetchCompany', async ({token, companyId}) => {
    const response = await api.get(`api/client/access/companies/${companyId}`, {
        headers: {'Access-Token': token},
    }).catch(err => {
        throw new Error(err.response.data.message[0]?.text);
    });
    return response.data;
})
export const addNewCompany = createAsyncThunk('companies/addNewCompany', async ({token, newCompany}) => {
    const response = await api.post('api/client/access/companies', {company: newCompany}, {
        headers: {'Access-Token': token},
    }).catch(err => {
        throw new Error(err.response.data.message[0]?.text);
    });
    return response.data;
})
export const updateCompany = createAsyncThunk('companies/updateCompany', async ({token, updatedCompany}) => {
    const response = await api.put(`api/client/access/companies/${updatedCompany.company_id}`, {company: updatedCompany}, {
        headers: {'Access-Token': token},
    }).catch(err => {
        throw new Error(err.response.data.message[0]?.text);
    });
    return response.data;
})
export const deleteCompany = createAsyncThunk('companies/deleteCompany', async ({token, companyId}) => {
    const response = await api.delete(`api/client/access/companies/${companyId}`, {
        headers: {'Access-Token': token},
    }).catch(err => {
        throw new Error(err.response.data.message[0]?.text);
    });
    return response.data;
})

const initialState = {
    companies: [],
    selectedCompany: {},
    status: {
        type: 'idle',
        error: null,
    },
    pagination: {pages: 0},
}

const companiesSlice = createSlice({
    name: 'companies',
    initialState,
    reducers: {
        setInitialState: state => initialState,
    },
    extraReducers: {
        [fetchCompanies.pending]: (state, action) => {
            state.status.type = 'loading'
        },
        [fetchCompanies.fulfilled]: (state, action) => {
            state.status.type = 'succeeded'
            state.companies = action.payload.data
            state.pagination = action.payload.pagination
        },
        [fetchCompanies.rejected]: (state, action) => {
            state.status.type = 'failed'
            state.status.error = action.error.message
        },

        [fetchCompany.pending]: (state, action) => {
            state.status.type = 'loading'
        },
        [fetchCompany.fulfilled]: (state, action) => {
            state.status.type = 'succeeded'
            state.selectedCompany = action.payload.data
        },
        [fetchCompany.rejected]: (state, action) => {
            state.status.type = 'failed'
            state.status.error = action.error.message
        },

        [addNewCompany.fulfilled]: (state, action) => {
            state.status.type = 'idle'
        },

        [deleteCompany.fulfilled]: (state, action) => {
            state.status.type = 'idle'
        },

        [updateCompany.pending]: (state, action) => {
            state.status.type = 'loading'
        },
        [updateCompany.fulfilled]: (state, action) => {
            state.status.type = 'succeeded'
            state.selectedCompany = action.payload.data
        }
    }
})

export const {setInitialState} = companiesSlice.actions
export default companiesSlice.reducer

export const selectAllCompanies = (state) => state.companies.companies
export const selectSelectedCompany = (state) => state.companies.selectedCompany
