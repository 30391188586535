import React, {useState} from 'react'
import {useDispatch, useSelector} from "react-redux"
import {updateCredential} from "../../../../redux/credentialsSlice";
import {Field, Form, Formik} from "formik";
import moment from "moment";
import {Checkbox} from "../../../../layout/Checkbox";
import StatusWrapper from "../../../../abstractComponents/StatusWrapper";

const TabUpdateFormComponent = ({credential}) => {
    const dispatch = useDispatch()

    const [status, setStatus] = useState(null)

    const authToken = useSelector((state) => state.auth.token)
    const credentialStatus = useSelector((state) => state.credentials.status)

    let printTimeout;
    const PrintStatus = () => {
        if (printTimeout) clearTimeout(printTimeout)
        switch (status) {
            case 'succeeded':
                printTimeout = setTimeout(() => {
                    setStatus(null)
                }, 6000)
                return <div className={'ms-3 mt-2 text-success'}><i className="fas fa-check"/> Applied</div>
            case 'loading':
                return <div className={'ms-3 mt-2 text-secondary'}><i className="fas fa-spinner fa-spin"/> Loading...
                </div>
            case 'error':
                printTimeout = setTimeout(() => {
                    setStatus(null)
                }, 15000)
                return <div className={'ms-3 mt-2 text-danger'}><i className="fas fa-times"/> Error</div>
            default:
                return null
        }
    }

    const onSaveCredentialClicked = async (values) => {
        try {
            setStatus('loading')
            let parsedValues = Object.assign({}, values)
            await dispatch(updateCredential({
                token: authToken,
                updatedCredential: {...credential, ...parsedValues}
            }))
            setStatus('succeeded')

            // await Notification.Toast.fire({icon: 'success', text: 'Updated'})
            // await Notification.Alert.fire({icon: 'success', text: 'Updated'})
        } catch (err) {
            console.error('Failed to save the credential: ', err)
            setStatus('error')
        }
    }

    let disabled = credentialStatus.type === 'loading'
    return <StatusWrapper statusSelector={credentialStatus}>
        <div className={'card-body'}>
            <Formik initialValues={credential} onSubmit={onSaveCredentialClicked}>
                {({values}) => (
                    <Form>
                        <fieldset>
                            <legend>Account info</legend>
                            <div className="form-floating mb-3">
                                <Field id={'credential_id'} name={'credential_id'} type={'text'}
                                       className={'form-control'}
                                       placeholder={'credential_id'}
                                       disabled={true}/>
                                <label htmlFor={'credential_id'}>ID</label>
                            </div>
                            <div className="form-floating mb-3">
                                <Field id={'name'} name={'name'} type={'text'}
                                       className={'form-control'}
                                       placeholder={'name'}
                                       disabled={disabled}/>
                                <label htmlFor={'name'}>Name</label>
                            </div>
                            <div className="form-floating mb-3">
                                <Field id={'surname'} name={'surname'} type={'text'}
                                       className={'form-control'}
                                       placeholder={'surname'}
                                       disabled={disabled}/>
                                <label htmlFor={'surname'}>Surname</label>
                            </div>
                            <div className="form-floating mb-3">
                                <Field id={'username'} name={'username'} type={'email'}
                                       className={'form-control'}
                                       placeholder={'username'}
                                       disabled={disabled}/>
                                <label htmlFor={'username'}>Email</label>
                            </div>
                            <div className="form-floating mb-3">
                                <Field id={'avatar_url'} name={'avatar_url'} type={'text'}
                                       className={'form-control'}
                                       placeholder={'avatar_url'}
                                       disabled={disabled}/>
                                <label htmlFor={'avatar_url'}>Avatar URL</label>
                            </div>
                            {values.avatar_url ?
                                <div className={'mb-3'}>
                                    <img src={values.avatar_url} className={'rounded'} alt={'Avatar'}
                                         style={{maxWidth: '50px', maxHeight: '50px'}}/>
                                </div> : null}
                        </fieldset>
                        <fieldset>
                            <legend>Visibility options</legend>
                            <div className={"form-check"}>
                                <Checkbox id={'deleted_at'}
                                          name={'deleted_at'}
                                          className={"form-check-input"}
                                          type={"checkbox"}
                                          valuechecked={moment.utc().format()}
                                          disabled={disabled}/>
                                <label className="form-check-label" htmlFor={"deleted_at"}>
                                    Soft-deleted
                                </label>
                            </div>
                        </fieldset>
                        <div className={'mt-3 d-flex align-content-center'}>
                            <button type={'submit'} className={'btn btn-success'}>Save</button>
                            <PrintStatus/>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    </StatusWrapper>
}
export default TabUpdateFormComponent