import React from 'react'
import {useDispatch, useSelector} from "react-redux"
import {fetchCredential} from "../../../../redux/credentialsSlice";
import Select from "react-select";
import {selectAllGroups, updateCredentialGroup} from "../../../../redux/groupsSlice";
import StatusWrapper from "../../../../abstractComponents/StatusWrapper";

const TabGroupsComponent = ({credential}) => {
    const dispatch = useDispatch()

    const authToken = useSelector((state) => state.auth.token)
    const credentialStatus = useSelector((state) => state.credentials.status)
    const groups = useSelector(selectAllGroups)
    const groupsStatus = useSelector((state) => state.groups.status)

    const onChangeGroups = async (values, action) => {
        await dispatch(updateCredentialGroup({token: authToken, credential, values, action}))
        await dispatch(fetchCredential({token: authToken, credentialId: credential.credential_id}))
    }

    let disabled = credentialStatus.type === 'loading'
    return <StatusWrapper statusSelector={credentialStatus}>
        <div className={'card-body'}>
            <Select getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.group_id}
                    value={credential.groups}
                    options={groups}
                    disabled={disabled}
                    isLoading={credentialStatus.type !== 'succeeded' || groupsStatus.type !== 'succeeded'}
                    onChange={onChangeGroups}/>
            {groupsStatus.type === 'failed' ?
                <div className={'alert alert-danger'}>{groupsStatus.error}</div>
                : null}

        </div>
    </StatusWrapper>
}
export default TabGroupsComponent