import React from "react";
import {Route, Switch, useRouteMatch} from "react-router";

import VideosPage from "./components/VideosPage";
import {Link} from "react-router-dom";

export default () => {
    let match = useRouteMatch();
    return <div className="row flex-xl-nowrap">
        <div className="col-md-3 col-xl-2 bd-sidebar"><h4>Indexer configuration</h4>
            <ul className="nav flex-column">
                <li className="nav-item">
                    <Link className="nav-link" to={`${match.path}/videos`}>Videos</Link>
                </li>
            </ul>
        </div>
        <main className="col-md-9 col-xl-8 py-md-3 pl-md-5 bd-content" role="main">
            <Switch>
                <Route path={`${match.path}/videos`} component={VideosPage}/>
                <Route exact path={`${match.path}/`}>
                    <h4>default content, some graphs?</h4>
                </Route>
            </Switch>
        </main>
    </div>
}