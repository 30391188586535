function convertJsonToCsv(json) {
	const {
		Parser
	} = require('json2csv');

	const opts = {
		// fields
	};

	try {
		const parser = new Parser(opts);
		const csv = parser.parse(json).replaceAll('"', '');
		return csv;
	} catch (err) {
		console.error(err);
	}
}

export default convertJsonToCsv