import React from "react";

const PasswordChecker = ({password}) => {
    const law1 = password.length < 8
    const law2 = !/(.*[a-z])+/.test(password)
    const law3 = !/(.*[A-Z])+/.test(password)
    const law4 = !/(.*\d)+/.test(password)
    return <ul>
        <li>
            <span className={!law1?` d-none`:``}><i className={`fas fa-times text-danger me-2`}/></span>
            <span className={law1?` d-none`:``}><i className={`fas fa-check text-success me-2 `}/></span>
            Password must contain minimum 8 characters
        </li>
        <li>
            <span className={!law2?` d-none`:``}><i className={`fas fa-times text-danger me-2`}/></span>
            <span className={law2?` d-none`:``}><i className={`fas fa-check text-success me-2 `}/></span>
            Password must contain at least lowercase character
        </li>
        <li>
            <span className={!law3?` d-none`:``}><i className={`fas fa-times text-danger me-2`}/></span>
            <span className={law3?` d-none`:``}><i className={`fas fa-check text-success me-2 `}/></span>
            Password must contains uppercase character
        </li>
        <li>
            <span className={!law4?` d-none`:``}><i className={`fas fa-times text-danger me-2`}/></span>
            <span className={law4?` d-none`:``}><i className={`fas fa-check text-success me-2 `}/></span>
            Password must contains at least one number
        </li>
    </ul>
}
export default PasswordChecker