import React from "react";

import 'bootstrap/dist/css/bootstrap.css';
import 'jquery/dist/jquery';
import 'bootstrap/dist/js/bootstrap';
import '@fortawesome/fontawesome-free/js/all';

import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Navbar from "./layout/Navbar";

import HomePage from "./modules/HomePage";
import IndexerContainer from "./modules/indexer/IndexerContainer";
import AccessContainer from "./modules/access/AccessContainer";
import LoginPage from "./modules/login/LoginPage";
import AuthGuard from "./layout/AuthGuard";
import Footer from "./layout/Footer";
import GoliathContainer from "./modules/goliath/GoliathContainer";
import {Provider} from "react-redux";
import {PersistGate} from 'redux-persist/integration/react'
import getStore from "./config/store";

const {store, persistor} = getStore()

function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={<p>Patatas</p>} persistor={persistor}>
                <Router>
                    <Switch>
                        <Route path="/login">
                            <LoginPage/>
                        </Route>
                        <Route>
                            <AuthGuard>
                                <Navbar/>

                                {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
                                <div className="container">
                                    <Switch>
                                        <Route path="/access" component={AccessContainer}/>
                                        <Route path="/indexer" component={IndexerContainer}/>
                                        <Route path="/goliath" component={GoliathContainer}/>
                                        {/*<Route exact path="/" component={HomePage}/>*/}
                                        <Route exact path="/" component={AccessContainer}/>
                                        <Route>
                                            <h1>404</h1>
                                        </Route>
                                    </Switch>
                                </div>
                                <Footer/>
                            </AuthGuard>
                        </Route>
                    </Switch>
                </Router>
            </PersistGate>
        </Provider>
    );
}

export default App;
