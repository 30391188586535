import {useDispatch, useSelector} from "react-redux";
import {renewToken} from "../redux/authSlice";
import {fetchConnections, selectAllConnections} from "../redux/connectionsSlice";
import React, {useEffect, useState} from "react";
import LoadingTools from "./LoadingTools";
import Select from "react-select";

export default ({reload = false}) => {
    const dispatch = useDispatch()
    const authToken = useSelector((state) => state.auth.token)
    const authExtra = useSelector(state => state.auth.extra)

    const [currentConnection, setCurrentConnection] = useState(null)

    useEffect(() => {
        dispatch(fetchConnections({token: authToken}))
        setCurrentConnection(authExtra && authExtra.connection ? authExtra.connection : '')
    }, [])

    const connections = useSelector(selectAllConnections)
    const connectionsStatus = useSelector(state => state.connections.status)

    const onChangeConnection = (values, action) => {
        setCurrentConnection(values)
        dispatch(renewToken({token: authToken, loginData: {connection: values}})).then(() => {
            if (reload) window.location.reload(false);
        })
    }

    const printConnections = () => {
        if (connectionsStatus.type === 'succeeded') {
            if (connections && connections.length > 0) {
                return <Select getOptionLabel={(option) => option.name}
                               getOptionValue={(option) => option.id}
                               value={currentConnection}
                               options={connections}
                               onChange={onChangeConnection}/>
            }
        } else {
            return <LoadingTools/>
        }
        return null;
    }
    return printConnections()
}