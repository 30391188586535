import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../config/api";

export const fetchCustomInsights = createAsyncThunk(
    "customInsights/fetchCustomInsights",
    async ({ token, company_id }) => {
        const response = await api
            .get(`api/client/global/insights/${company_id}`, {
                headers: { "Access-Token": token },
            })
            .catch((err) => {
                throw new Error(err.response.data.message[0]?.text);
            });
        return response.data;
    }
);
// export const fetchLanguages = createAsyncThunk('insights/fetchLanguages', async ({token}) => {
//     const response = await api.get(`api/client/global/languages/types`, {
//         headers: {'Access-Token': token},
//     }).catch(err => {
//         throw new Error(err.response.data.message[0]?.text);
//     });
//     return response.data;
// })
export const fetchFeaturesTypes = createAsyncThunk(
    "customInsights/fetchFeaturesTypes",
    async ({ token }) => {
        const response = await api
            .get(`api/client/global/features/types`, {
                headers: { "Access-Token": token },
            })
            .catch((err) => {
                throw new Error(err.response.data.message[0]?.text);
            });
        return response.data;
    }
);

export const fetchLanguagesTypes = createAsyncThunk(
    "customInsights/fetchLanguagesTypes",
    async ({ token }) => {
        const response = await api
            .get(`api/client/global/languages/types`, {
                headers: { "Access-Token": token },
            })
            .catch((err) => {
                throw new Error(err.response.data.message[0]?.text);
            });
        return response.data;
    }
);

export const updateCustomInsights = createAsyncThunk(
    "customInsights/updateCustomInsights",
    async ({ token, company_id, values }) => {
        const response = await api
            .put(
                `api/client/global/insights/${company_id}`,
                { customInsights: values },
                {
                    headers: { "Access-Token": token },
                }
            )
            .catch((err) => {
                throw new Error(err.response.data.message[0]?.text);
            });
        return response.data;
    }
);

export const addCustomInsights = createAsyncThunk(
    "customInsights/addCustomInsights",
    async ({ token, company_id, values }) => {
        const response = await api
            .post(
                `api/client/global/insights/${company_id}`,
                { customInsights: values },
                {
                    headers: { "Access-Token": token },
                }
            )
            .catch((err) => {
                throw new Error(err.response.data.message[0]?.text);
            });
        return response.data;
    }
);

export const deleteCustomInsights = createAsyncThunk(
    "customInsights/deleteCustomInsights",
    async ({ token, company_id }) => {
        const response = await api
            .delete(
                `api/client/global/insights/${company_id}`,
                {
                    headers: { "Access-Token": token },
                }
            )
            .catch((err) => {
                throw new Error(err.response.data.message[0]?.text);
            });
        return response.data;
    }
);

const initialState = {
    customInsights: {
        data: [],
        status: {
            type: "idle",
            error: null,
        },
    },
    featuresTypes: {
        data: [],
        status: {
            type: "idle",
            error: null,
        },
    },
    languagesTypes: {
        data: [],
        status: {
            type: "idle",
            error: null,
        },
    },
};

const customInsightsSlice = createSlice({
    name: "customInsights",
    initialState,
    reducers: {
        setInitialState: (state) => initialState,
    },
    extraReducers: {
        [fetchCustomInsights.pending]: (state, action) => {
            state.customInsights.status.type = "loading";
        },
        [fetchCustomInsights.fulfilled]: (state, action) => {
            state.customInsights.status.type = "succeeded";
            state.customInsights.data = action.payload.data;
        },
        [fetchCustomInsights.rejected]: (state, action) => {
            state.customInsights.status.type = "failed";
            state.customInsights.status.error = action.error.message;
        },
        [fetchFeaturesTypes.pending]: (state, action) => {
            state.featuresTypes.status.type = "loading";
        },
        [fetchFeaturesTypes.fulfilled]: (state, action) => {
            state.featuresTypes.status.type = "succeeded";
            state.featuresTypes.data = action.payload.data;
        },
        [fetchFeaturesTypes.rejected]: (state, action) => {
            state.featuresTypes.status.type = "failed";
            state.featuresTypes.status.error = action.error.message;
        },
        [fetchLanguagesTypes.pending]: (state, action) => {
            state.languagesTypes.status.type = "loading";
        },
        [fetchLanguagesTypes.fulfilled]: (state, action) => {
            state.languagesTypes.status.type = "succeeded";
            state.languagesTypes.data = action.payload.data;
        },
        [fetchLanguagesTypes.rejected]: (state, action) => {
            state.languagesTypes.status.type = "failed";
            state.languagesTypes.status.error = action.error.message;
        },
        [updateCustomInsights.pending]: (state, action) => {
            state.customInsights.status.type = "loading";
        },
        [updateCustomInsights.fulfilled]: (state, action) => {
            state.customInsights.status.type = "succeeded";
            state.customInsights.data = action.payload.data;
        },
        [updateCustomInsights.rejected]: (state, action) => {
            state.customInsights.status.type = "failed";
            state.customInsights.status.error = action.error.message;
        },
        [addCustomInsights.pending]: (state, action) => {
            state.customInsights.status.type = "loading";
        },
        [addCustomInsights.fulfilled]: (state, action) => {
            state.customInsights.status.type = "succeeded";
            state.customInsights.data = action.payload.data;
        },
        [addCustomInsights.rejected]: (state, action) => {
            state.customInsights.status.type = "failed";
            state.customInsights.status.error = action.error.message;
        },
        [deleteCustomInsights.pending]: (state, action) => {
            state.customInsights.status.type = "loading";
        },
        [deleteCustomInsights.fulfilled]: (state, action) => {
            state.customInsights.status.type = "succeeded";
            state.customInsights.data = action.payload.data;
        },
        [deleteCustomInsights.rejected]: (state, action) => {
            state.customInsights.status.type = "failed";
            state.customInsights.status.error = action.error.message;
        },
    },
});

export const { setInitialState } = customInsightsSlice.actions;
export default customInsightsSlice.reducer;

export const selectCustomInsights = (state) =>
    state.customInsights.customInsights.data;
export const selectFeaturesTypes = (state) =>
    state.customInsights.featuresTypes.data;
export const selectLanguagesTypes = (state) =>
    state.customInsights.languagesTypes.data;
export const selectCustomInsightsStatus = (state) =>
    state.customInsights.customInsights.status;
