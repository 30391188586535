import React, {useState} from 'react'
import {useDispatch, useSelector} from "react-redux"
import {addNewCompany} from "../../../redux/companiesSlice";
import {Field, Form, Formik} from "formik";

export default () => {
    const [status, setStatus] = useState(null)

    const authToken = useSelector((state) => state.auth.token)
    const dispatch = useDispatch()

    const PrintStatus = () => {
        setTimeout(() => {
            setStatus(null)
        }, 6000)
        switch (status) {
            case 'succeeded':
                return <div className={'ms-3 mt-2 text-success'}><i className="fas fa-check"/> Applied</div>
            case 'loading':
                return <div className={'ms-3 mt-2 text-secondary'}><i className="fas fa-spinner fa-spin"/> Loading...
                </div>
            case 'error':
                return <div className={'ms-3 mt-2 text-danger'}><i className="fas fa-times"/> Error</div>
            default:
                return null
        }
    }

    const onSaveCompanyClicked = async (values, {resetForm}) => {
        try {
            setStatus('loading')
            await dispatch(addNewCompany({token: authToken, newCompany: values}))
            setStatus('succeeded')
            resetForm()
        } catch (err) {
            console.error('Failed to save the credential: ', err)
            setStatus('error')
        }
    }

    return <div className={'card'}>
        <div className={'card-body'}>
            <div className={"d-flex justify-content-between mb-3"}>
                <h3 className={'card-title'}>Create new company</h3>
            </div>
            <Formik initialValues={{name: '', url: ''}} onSubmit={onSaveCompanyClicked}>
                <Form>
                    <div className="form-floating mb-3">
                        <Field id={'name'} name={'name'} type={'text'} className={'form-control'} placeholder={'Name'}/>
                        <label htmlFor={'name'}>Name</label>
                    </div>
                    <div className="mb-3">
                        <div className="input-group mb-3">
                            <div className={'form-floating'}>
                                <Field id={'url'} name={'url'} type={'text'} required className={'form-control'}
                                       placeholder={'Url Prefix'}/>
                                <label htmlFor={'url'}>Url Prefix</label>
                            </div>
                            <span className="input-group-text" id="basic-addon2">.app.winnow.video</span>
                        </div>
                    </div>
                    <div className="form-floating mb-3">
                        <Field id={'limit_indexing'} name={'limit_indexing'} type={'number'} className={'form-control'}
                               placeholder={'Indexing Limits'}/>
                        <label htmlFor={'limit_indexing'}>Indexing Limits</label>
                    </div>
                    <div className={'mt-3 d-flex align-content-center'}>
                        <button type={'submit'} className={'btn btn-success'}>Save</button>
                        <PrintStatus/>
                    </div>
                </Form>
            </Formik>
        </div>
    </div>
}