import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import api from "../config/api";

export const fetchCredentials = createAsyncThunk('credentials/fetchCredentials', async ({token, page = null}) => {
    const response = await api.get(`api/goliath/access/credentials/list/${(page !== null ? page : '')}`, {
        headers: {'Access-Token': token},
    }).catch(err => {
        throw new Error(err.response.data.message[0]?.text);
    });
    return response.data;
})
export const fetchCredential = createAsyncThunk('credentials/fetchCredential', async ({token, credentialId}) => {
    const response = await api.get(`api/goliath/access/credentials/${credentialId}`, {
        headers: {'Access-Token': token},
    }).catch(err => {
        throw new Error(err.response.data.message[0]?.text);
    });
    return response.data;
})
export const addNewCredential = createAsyncThunk('credentials/addNewCredential', async ({token, newCredential}) => {
    const response = await api.post('api/goliath/access/credentials', {credential: newCredential}, {
        headers: {'Access-Token': token},
    }).catch(err => {
        throw new Error(err.response.data.message[0]?.text);
    });
    return response.data;
})
export const updateCredential = createAsyncThunk('credentials/updateCredential', async ({token, updatedCredential}) => {
    const response = await api.put(`api/goliath/access/credentials/${updatedCredential.credential_id}`, {credential: updatedCredential}, {
        headers: {'Access-Token': token},
    }).catch(err => {
        throw new Error(err.response.data.message[0]?.text);
    });
    return response.data;
})
export const updateCredentialCompany = createAsyncThunk('credentials/updateCredentialCompany', async ({token, credentialSelected, values, action}) => {
    const response = await api.put(`api/goliath/access/credentials/${credentialSelected.credential_id}/companies`, {
        companies: values,
        action: action,
    }, {
        headers: {'Access-Token': token},
    }).catch(err => {
        throw new Error(err.response.data.message[0]?.text);
    });
    return response.data;
})
export const deleteCredential = createAsyncThunk('credentials/deleteCredential', async ({token, credentialId}) => {
    const response = await api.delete(`api/goliath/access/credentials/${credentialId}`, {
        headers: {'Access-Token': token},
    }).catch(err => {
        throw new Error(err.response.data.message[0]?.text);
    });
    return response.data;
})
export const resetPassword = createAsyncThunk('credentials/resetPassword', async ({token, credentialId}) => {
    const response = await api.get(`api/goliath/access/credentials/${credentialId}/password/resend`, {
        headers: {'Access-Token': token},
    }).catch(err => {
        throw new Error(err.response.data.message[0]?.text);
    });
    return response.data;
})

const initialState = {
    credentials: [],
    selectedCredential: {},
    status: {
        type: 'idle',
        error: null,
    },
    pagination: {pages: 0},
}

const goliathCredentialsSlice = createSlice({
    name: 'goliathCredentials',
    initialState,
    reducers: {
        setInitialState: state => initialState,
    },
    extraReducers: {
        [fetchCredentials.pending]: (state, action) => {
            state.status.type = 'loading'
        },
        [fetchCredentials.fulfilled]: (state, action) => {
            state.status.type = 'succeeded'
            state.credentials = action.payload.data
            state.pagination = action.payload.pagination
        },
        [fetchCredentials.rejected]: (state, action) => {
            state.status.type = 'failed'
            state.status.error = action.error.message
        },

        [fetchCredential.pending]: (state, action) => {
            state.status.type = 'loading'
        },
        [fetchCredential.fulfilled]: (state, action) => {
            state.status.type = 'succeeded'
            state.selectedCredential = action.payload.data
        },
        [fetchCredential.rejected]: (state, action) => {
            state.status.type = 'failed'
            state.status.error = action.error.message
        },

        [addNewCredential.fulfilled]: (state, action) => {
            state.status.type = 'idle'
        },
        [addNewCredential.pending]: (state, action) => {
            state.status.type = 'loading'
        },
        [addNewCredential.rejected]: (state, action) => {
            state.status.type = 'failed'
            state.status.error = action.error.message
        },

        [deleteCredential.fulfilled]: (state, action) => {
            state.status.type = 'idle'
        },
        [deleteCredential.pending]: (state, action) => {
            state.status.type = 'loading'
        },
        [deleteCredential.rejected]: (state, action) => {
            state.status.type = 'failed'
            state.status.error = action.error.message
        },

        [updateCredential.pending]: (state, action) => {
            state.status.type = 'loading'
        },
        [updateCredential.fulfilled]: (state, action) => {
            state.status.type = 'succeeded'
            state.selectedCredential = action.payload.data
        },
        [updateCredential.rejected]: (state, action) => {
            state.status.type = 'failed'
            state.status.error = action.error.message
        },

        [updateCredentialCompany.pending]: (state, action) => {
            state.status.type = 'loading'
        },
        [updateCredentialCompany.fulfilled]: (state, action) => {
            state.status.type = 'succeeded'
            state.selectedCredential = action.payload.data
        },
        [updateCredentialCompany.rejected]: (state, action) => {
            state.status.type = 'failed'
            state.status.error = action.error.message
        },

        [resetPassword.pending]: (state, action) => {
            state.status.type = 'loading'
        },
        [resetPassword.fulfilled]: (state, action) => {
            state.status.type = 'succeeded'
            state.selectedCredential = action.payload.data
        },
        [resetPassword.rejected]: (state, action) => {
            state.status.type = 'failed'
            state.status.error = action.error.message
        },
    }
})

export const {setInitialState} = goliathCredentialsSlice.actions
export default goliathCredentialsSlice.reducer

export const selectAllCredentials = (state) => state.credentials.credentials
export const selectSelectedCredential = (state) => state.credentials.selectedCredential
