import React from "react";
import {Field} from "formik";

export function Checkbox(props) {
    const onClickCheck = (field, form) => {
        if (!field.value) {
            form.setFieldValue(props.name, props.valuechecked ?? true);
        } else {
            form.setFieldValue(props.name, props.valuechecked ? null : false);
        }
    }
    return (
        <Field name={props.name}>
            {({field, form}) => (
                <label>
                    <input
                        type="checkbox"
                        {...props}
                        checked={field.value !== null && field.value !== false}
                        onChange={() => onClickCheck(field, form)}
                    />
                </label>
            )}
        </Field>
    );
}