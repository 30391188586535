import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux"
import {
    deleteCredential,
    fetchCredential,
    selectSelectedCredential,
    updateCredential,
} from "../../../redux/goliathCredentialsSlice";
import * as Notify from "../../../layout/Notify";
import {Field, Form, Formik} from "formik";
import {useHistory} from "react-router";
import Select from "react-select";
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import {fetchGroups, selectAllGroups, updateCredentialGroup} from "../../../redux/groupsSlice";
import LoadingTools from "../../../layout/LoadingTools";
import moment from "moment";
import {Checkbox} from "../../../layout/Checkbox";

export default ({match}) => {
    const [status, setStatus] = useState(null)

    const authToken = useSelector((state) => state.auth.token)

    const credentialSelected = useSelector(selectSelectedCredential)
    const credentialStatus = useSelector((state) => state.credentials.status)

    const groups = useSelector(selectAllGroups)
    const groupsStatus = useSelector((state) => state.groups.status)

    const dispatch = useDispatch()
    const currentCredentialId = match.params.credentialId

    let history = useHistory();

    const confirmDelete = async () => Notify.Alert.fire({
        title: 'Are you sure?',
        html: `You're going to delete <code>${credentialSelected.name}</code>, you won't be able to revert this!`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#5cd630',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        preConfirm: async () => await dispatch(deleteCredential({token: authToken, credentialId: currentCredentialId})),
        allowOutsideClick: () => !Notify.Alert.isLoading()
    })
        .then(async (result) => {
            if (result.isConfirmed) {
                await Notify.Alert.fire(
                    'Deleted!',
                    'User has been deleted.',
                    'success'
                )
            }
        })
        .catch(async () =>
            await Notify.Alert.fire(
                'Error',
                'User has not been deleted.',
                'danger'
            ))
        .finally(async () => {
            history.push('/goliath/access/credentials/')
        })

    useEffect(() => dispatch(fetchCredential({token: authToken, credentialId: currentCredentialId})), [])
    useEffect(() => dispatch(fetchGroups({token: authToken})), [])

    let printTimeout;
    const PrintStatus = () => {
        if (printTimeout) clearTimeout(printTimeout)
        switch (status) {
            case 'succeeded':
                printTimeout = setTimeout(() => {
                    setStatus(null)
                }, 6000)
                return <div className={'ms-3 mt-2 text-success'}><i className="fas fa-check"/> Applied</div>
            case 'loading':
                return <div className={'ms-3 mt-2 text-secondary'}><i className="fas fa-spinner fa-spin"/> Loading...
                </div>
            case 'error':
                printTimeout = setTimeout(() => {
                    setStatus(null)
                }, 15000)
                return <div className={'ms-3 mt-2 text-danger'}><i className="fas fa-times"/> Error</div>
            default:
                return null
        }
    }

    const onChangeGroups = async (values, action) => {
        try {
            console.log(values, action)
            setStatus('loading')
            await dispatch(updateCredentialGroup({token: authToken, credentialSelected, values, action}))
            await dispatch(fetchCredential({token: authToken, currentCredentialId}))
            setStatus('succeeded')
            // await Notification.Toast.fire({icon: 'success', text: 'Updated'})
            // await Notification.Alert.fire({icon: 'success', text: 'Updated'})
        } catch (err) {
            console.error('Failed to save the credential company: ', err)
            setStatus('error')
        }
    }

    const onSaveCredentialClicked = async (values) => {
        try {
            setStatus('loading')
            let parsedValues = Object.assign({}, values)
            if (parsedValues.password && parsedValues.password.length > 0) {
                parsedValues.password = btoa(parsedValues.password)
            }
            await dispatch(updateCredential({
                token: authToken,
                updatedCredential: {...credentialSelected, ...parsedValues}
            }))
            setStatus('succeeded')

            // await Notification.Toast.fire({icon: 'success', text: 'Updated'})
            // await Notification.Alert.fire({icon: 'success', text: 'Updated'})
        } catch (err) {
            console.error('Failed to save the credential: ', err)
            setStatus('error')
        }
    }

    let disabled = credentialStatus.type === 'loading'
    if (credentialStatus.type === 'succeeded' || (credentialSelected.credential_id && credentialStatus.type === 'loading')) {
        return <Tabs>
            <div className={'card'}>
                <div style={{background: 'linear-gradient(to right, #ece9e6, #ffffff)'}}>
                    <div className={'card-body d-flex'}>
                        {credentialSelected.avatar_url ?
                            <div className={'me-3'}>
                                <img src={credentialSelected.avatar_url} className={'rounded'} alt={'Avatar'}
                                     style={{maxWidth: '50px', maxHeight: '50px'}}/>
                            </div> : null}
                        <h3>{credentialSelected.username}</h3>
                    </div>
                    <TabList className={'nav nav-tabs'}>
                        <Tab className={'nav-link btn btn-link'} selectedClassName={'active'}>Basic data</Tab>
                        <Tab className={'nav-link btn btn-link'} selectedClassName={'active'}>Permissions</Tab>
                    </TabList>
                </div>
                <TabPanel selected={true}>
                    <div className={'card-body'}>
                        <div className={"d-flex justify-content-between mb-3"}>
                            <button className={"btn btn-danger me-3 btn-sm"} type={'button'}
                                    onClick={() => confirmDelete()}><i className={"far fa-trash-alt"}/> Remove
                            </button>
                        </div>
                        <Formik initialValues={credentialSelected} onSubmit={onSaveCredentialClicked}>
                            {({values}) => (
                                <Form>
                                    <fieldset>
                                        <legend>Account info</legend>
                                        <div className="form-floating mb-3">
                                            <Field id={'credential_id'} name={'credential_id'} type={'text'}
                                                   className={'form-control'}
                                                   placeholder={'credential_id'}
                                                   disabled={true}/>
                                            <label htmlFor={'credential_id'}>ID</label>
                                        </div>
                                        <div className="form-floating mb-3">
                                            <Field id={'name'} name={'name'} type={'text'}
                                                   className={'form-control'}
                                                   placeholder={'name'}
                                                   disabled={disabled}/>
                                            <label htmlFor={'name'}>Name</label>
                                        </div>
                                        <div className="form-floating mb-3">
                                            <Field id={'surname'} name={'surname'} type={'text'}
                                                   className={'form-control'}
                                                   placeholder={'surname'}
                                                   disabled={disabled}/>
                                            <label htmlFor={'surname'}>Surname</label>
                                        </div>
                                        <div className="form-floating mb-3">
                                            <Field id={'username'} name={'username'} type={'text'}
                                                   className={'form-control'}
                                                   placeholder={'username'}
                                                   disabled={disabled}/>
                                            <label htmlFor={'username'}>Username (email is recommended)</label>
                                        </div>
                                        <div className="form-floating mb-3">
                                            <Field id={'avatar_url'} name={'avatar_url'} type={'text'}
                                                   className={'form-control'}
                                                   placeholder={'avatar_url'}
                                                   disabled={disabled}/>
                                            <label htmlFor={'avatar_url'}>Avatar URL</label>
                                        </div>
                                        {values.avatar_url ?
                                            <div className={'mb-3'}>
                                                <img src={values.avatar_url} className={'rounded'} alt={'Avatar'}
                                                     style={{maxWidth: '50px', maxHeight: '50px'}}/>
                                            </div> : null}
                                    </fieldset>
                                    <fieldset>
                                        <legend>Secret keys</legend>
                                        <p className={'alert alert-warning'}>An empty field won't change the
                                            password.
                                            Password only will be changed if this field is filled.</p>
                                        <div className="form-floating mb-3">
                                            <Field id={'password'} name={'password'} type={'text'}
                                                   className={'form-control'}
                                                   placeholder={'password'} disabled={disabled}
                                                   value={values.password ?? ''}/>
                                            <label htmlFor={'password'}>Change credential password</label>
                                        </div>
                                    </fieldset>
                                    <fieldset>
                                        <legend>Visibility options</legend>
                                        <div className={"form-check"}>
                                            <Checkbox id={'deleted_at'}
                                                      name={'deleted_at'}
                                                      className={"form-check-input"}
                                                      type={"checkbox"}
                                                      valuechecked={moment.utc().format()}
                                                      disabled={disabled}/>
                                            <label className="form-check-label" htmlFor={"deleted_at"}>
                                                Soft-deleted
                                            </label>
                                        </div>
                                    </fieldset>
                                    <div className={'mt-3 d-flex align-content-center'}>
                                        <button type={'submit'} className={'btn btn-success'}>Save</button>
                                        <PrintStatus/>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={'card-body'}>
                        {credentialStatus.type === 'succeeded' && groupsStatus.type === 'succeeded' ?
                            <Select getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.group_id}
                                    value={credentialSelected.groups}
                                    options={groups}
                                    disabled={disabled}
                                    onChange={onChangeGroups}/>
                            : null}
                        {groupsStatus.type === 'loading' ?
                            <LoadingTools/>
                            : null}
                        {groupsStatus.type === 'failed' ?
                            <div className={'alert alert-danger'}>{groupsStatus.error}</div>
                            : null}

                    </div>
                </TabPanel>
            </div>
        </Tabs>
    }
    if (!credentialSelected.credential_id && groupsStatus.type === 'loading') {
        return <LoadingTools/>
    }
    if (credentialStatus.type === 'failed') {
        return <div className={'alert alert-danger'}>{credentialStatus.error}</div>
    }
    if (groupsStatus.type === 'failed') {
        return <div className={'alert alert-danger'}>{groupsStatus.error}</div>
    }
    return <div/>
}