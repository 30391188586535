import LoadingTools from "../layout/LoadingTools";
import React from "react";

const StatusWrapper = ({statusSelector, children}) => {
    if (statusSelector.type === 'succeeded') {
        return <>{children}</>
    }
    if (statusSelector.type === 'failed') {
        return <div className={'alert alert-danger'}>{statusSelector.error}</div>
    }
    return <LoadingTools/>
}
export default StatusWrapper