import {Field, Form, Formik} from "formik";
import {useState} from "react";
import {setInitialState, tryLogin} from "../../redux/authSlice";
import {useDispatch, useSelector} from "react-redux";
import LoadingTools from "../../layout/LoadingTools";
import {useHistory} from "react-router";
import SelectDatabase from "../../layout/SelectDatabase";

export default () => {
    let [username, setUsername] = useState('')
    let [password, setPassword] = useState('')
    let [formDisabled, setFormDisabled] = useState(false)

    const authStatus = useSelector(state => state.auth.status)
    const authToken = useSelector(state => state.auth.token)
    const authCredential = useSelector(state => state.auth.credential)
    const authExtra = useSelector(state => state.auth.extra)

    const history = useHistory();
    const dispatch = useDispatch()

    const onRetry = async () => {
        await dispatch(setInitialState())
        setFormDisabled(false)
    }

    const onSubmitTryLogin = async (values, {resetForm}) => {
        setFormDisabled(true)
        let loginParams = {
            type: 'account',
            username: values.username,
            password: btoa(values.password)
        }
        console.log('trying to login')
        dispatch(tryLogin(loginParams))
    }
    let container;
    if (authStatus.type === 'idle') {
        container = <div className={'card-body'}>
                    <h2 className="h3 mb-3 fw-normal">Please, sign in</h2>
                    <Formik initialValues={{username, password}} onSubmit={onSubmitTryLogin}>
                        <Form>
                            <div className="form-floating mb-3">
                                <Field id={'username'}
                                       name={'username'}
                                       type={'text'}
                                       className={'form-control'}
                                       placeholder={'username'} disabled={formDisabled}
                                       autoFocus/>
                                <label htmlFor={'username'}>Username</label>
                            </div>
                            <div className="form-floating mb-3">
                                <Field id={'password'}
                                       name={'password'}
                                       type={'password'}
                                       className={'form-control'}
                                       placeholder={'password'} disabled={formDisabled}/>
                                <label htmlFor={'password'}>Password</label>
                            </div>
                            <div className="d-grid gap-2">
                                <button type="submit" className="w-100 btn btn-lg btn-primary" disabled={formDisabled}>
                                    Sign In
                                </button>
                            </div>
                        </Form>
                    </Formik>
        </div>
    } else if (authStatus.type === 'succeeded') {
        if (authExtra && authExtra.connection) {
            container = <div className={'card-body'}>
                <h4 className="alert-heading">{authCredential.username}</h4>
                <p>Has been logged successfull on {authExtra.connection.name}</p>
                <LoadingTools/>
            </div>
            setTimeout(() => history.push('/access/'), 2000)
        } else {
            container = <div className={'card-body'}>
                <h4 className="alert-heading">Please {authCredential.username}, select a database</h4>
                <div><SelectDatabase/></div>
            </div>
        }

    } else if (authStatus.type === 'loading') {
        container = <div className={'card-body text-center'}><LoadingTools/></div>
    } else if (authStatus.type === 'failed') {
        container = <div className={'card-body'}>
            <div className={'alert alert-danger'}>
                <h4 className="alert-heading">Error</h4>
                <p>{authStatus.error}</p>
                <button onClick={() => onRetry()} className={'btn btn-light'}>Retry</button>
            </div>
        </div>
    }


    return (<div className={'w-100 d-flex justify-content-center align-items-center'}
                 style={{height: '100vh', background: 'radial-gradient(#414345,#232526)'}}>
        <div className={'col-11 col-sm-6 col-md-4 col-lg-4 col-xl-3'}>
            <div className={'card bg-light'}>
                {container}
            </div>
        </div>
    </div>)
}