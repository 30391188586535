import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedCompany } from "../../../../redux/companiesSlice";
import LoadingTools from "../../../../layout/LoadingTools";
import * as XLSX from "xlsx";
import DataTable from "react-data-table-component";
import * as Notify from "../../../../layout/Notify";
import {
    fetchCustomInsights,
    selectCustomInsights,
    selectFeaturesTypes,
    selectLanguagesTypes,
    selectCustomInsightsStatus,
    fetchLanguagesTypes,
    fetchFeaturesTypes,
    updateCustomInsights,
    addCustomInsights,
    deleteCustomInsights,
} from "../../../../redux/customInsightsSlice";
import convertJsonToCsv from "../../../../helpers/csvJsonTools";

export const CustomInsightsForm = () => {
    const [status, setStatus] = useState(null);

    const authToken = useSelector((state) => state.auth.token);
    const companySelected = useSelector(selectSelectedCompany);
    const currentInsights = useSelector(selectCustomInsights);
    const featuresTypes = useSelector(selectFeaturesTypes);
    const languagesTypes = useSelector(selectLanguagesTypes);
    const currentInsightsStatus = useSelector(selectCustomInsightsStatus);

    const [currentColumns, setCurrentColumns] = useState([]);
    const [currentData, setCurrentData] = useState([]);
    const [newColumns, setNewColumns] = useState([]);
    const [newData, setNewData] = useState([]);
    const [warningItems, setWarningItems] = useState([]);
    const [file, setFile] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            fetchCustomInsights({
                token: authToken,
                company_id: companySelected.company_id,
            })
        );
        dispatch(
            fetchFeaturesTypes({
                token: authToken,
                company_id: companySelected.company_id,
            })
        );
        dispatch(
            fetchLanguagesTypes({
                token: authToken,
                company_id: companySelected.company_id,
            })
        );
        return () => {};
    }, []);

    useEffect(() => {
        if (status === "succeeded") {
            setNewData([]);
            setNewColumns([]);
        }
    }, [status]);

    useEffect(() => {
        if (currentInsights?.length) {
            const cleanJson = currentInsights.map((insight) => {
                return {
                    type: insight.type?.key,
                    language:
                        insight.language === null
                            ? "*"
                            : insight.language?.code,
                    value: insight.value,
                };
            });
            const data = convertJsonToCsv(cleanJson);
            const { list, columns } = processCsvData(data);
            setCurrentData(list);
            setCurrentColumns(columns);
        } else {
            setCurrentData([]);
            setCurrentColumns([]);
        }
    }, [currentInsights]);

    const confirmDelete = async () =>
        Notify.Alert.fire({
            title: "Are you sure?",
            html: `You're going to clear the <b>Custom Insights</b> of <b><code>${companySelected.name}</code></b>, you won't be able to revert this!`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#5cd630",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, clear it!",
            preConfirm: onDeleteClicked,
            allowOutsideClick: () => !Notify.Alert.isLoading(),
        })
            .then(async (result) => {
                if (result.isConfirmed) {
                    await Notify.Alert.fire(
                        "Deleted!",
                        "Custom Insights have been cleared.",
                        "success"
                    );
                }
            })
            .catch(
                async () =>
                    await Notify.Alert.fire(
                        "Error",
                        "Custom Insights have not been cleared.",
                        "danger"
                    )
            )
            .finally(async () => {});

    const processCsvData = (dataString) => {
        const dataStringLines = dataString.split(/\r\n|\n/);
        const headers = dataStringLines[0].split(
            /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
        );

        const list = [];
        for (let i = 1; i < dataStringLines.length; i++) {
            const row = dataStringLines[i].split(
                /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
            );
            if (headers && row.length == headers.length) {
                const obj = {};
                for (let j = 0; j < headers.length; j++) {
                    let d = row[j];
                    if (d.length > 0) {
                        if (d[0] == '"') d = d.substring(1, d.length - 1);
                        if (d[d.length - 1] == '"')
                            d = d.substring(d.length - 2, 1);
                    }
                    if (headers[j]) {
                        obj[headers[j]] = d;
                    }
                }

                // remove the blank rows
                if (Object.values(obj).filter((x) => x).length > 0) {
                    list.push(obj);
                }
            }
        }

        // prepare columns list from headers
        const columns = headers.map((c) => ({
            name: c,
            selector: c,
        }));

        return { list, columns };
    };

    const handleFileUpload = (e) => {
        setWarningItems([]);
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (evt) => {
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: "binary" });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            let { list, columns } = processCsvData(data);
            setNewData(list);
            setNewColumns(columns);
        };
        reader.readAsBinaryString(file);
        setFile(file);
    };

    const onOverwriteClicked = async () => {
        try {
            var data = convertToTypifiedData(newData);
        } catch (err) {
            console.error("Invalid data format: ", err);
            setStatus("error");
        }
        try {
            setStatus("loading");
            dispatch(
                updateCustomInsights({
                    token: authToken,
                    company_id: companySelected.company_id,
                    values: data,
                })
            );
            setStatus("succeeded");
            setFile(null);
        } catch (err) {
            console.error("Failed saving custom insights: ", err);
            setStatus("error");
        }
    };

    const onAddClicked = async () => {
        try {
            var data = convertToTypifiedData(newData);
        } catch (err) {
            console.error("Invalid data format: ", err);
            setStatus("error");
        }
        try {
            setStatus("loading");
            dispatch(
                addCustomInsights({
                    token: authToken,
                    company_id: companySelected.company_id,
                    values: data,
                })
            );
            setStatus("succeeded");
            setFile(null);
        } catch (err) {
            console.error("Failed saving custom insights: ", err);
            setStatus("error");
        }
    };

    const onDeleteClicked = async () => {
        try {
        } catch (err) {
            console.error("Invalid data format: ", err);
            setStatus("error");
        }
        try {
            setStatus("loading");
            dispatch(
                deleteCustomInsights({
                    token: authToken,
                    company_id: companySelected.company_id,
                })
            );
            setStatus("succeeded");
        } catch (err) {
            console.error("Failed saving custom insights: ", err);
            setStatus("error");
        }
    };

    const convertToTypifiedData = (data) => {
        setWarningItems([]);
        if (!data?.length) {
            console.error("Invalid data.");
            setStatus("error");
        }
        const typifiedData = data.map((element) => {
            const typified = {
                type_id: getTypifiedId(element["type"], featuresTypes, "key"),
                language_id:
                    element["language"] === "*"
                        ? null
                        : getTypifiedId(
                              element["language"],
                              languagesTypes,
                              "code"
                          ),
                value: element["value"],
            };
            if (
                typified?.language_id !== undefined &&
                typified?.type_id &&
                typified?.value
            ) {
                return typified;
            } else {
                let warnings = warningItems;
                if (element?.value) warnings.push(element?.value);
                else warnings.push("<empty>");
                setWarningItems(warnings);
            }
        });
        return typifiedData;
    };

    const getTypifiedId = (value, typification, valuePropName) => {
        return typification.find(
            (t) =>
                t[valuePropName].toLowerCase().trim().replaceAll(" ", "") ===
                value.toLowerCase().trim().replaceAll(" ", "")
        )?.id;
    };

    return (
        <>
            <div>
                <h3 className={"card-title"}>Current Custom Insights</h3>
                {warningItems?.length ? (
                    <p className="alert alert-warning">
                        Some insights are invalid and has been ignored:{" "}
                        <h6>{`${warningItems}`}</h6>
                    </p>
                ) : null}
                {currentInsightsStatus?.type === "succeeded" ? (
                    <DataTable
                        pagination
                        highlightOnHover
                        columns={currentColumns}
                        data={currentData}
                    />
                ) : currentInsightsStatus?.type === "failed" ? (
                    <div className={"alert alert-danger"}>
                        {currentInsightsStatus.error}
                    </div>
                ) : (
                    <LoadingTools />
                )}
            </div>
            <div>
                <h3 className={"card-title"}>Upload CSV or Excel file</h3>
                <div className={"d-flex justify-content-between mb-3"}>
                    <input
                        type="file"
                        accept=".csv,.xlsx,.xls"
                        onChange={handleFileUpload}
                    />
                </div>
                {file?.name ? (
                    <DataTable
                        pagination
                        highlightOnHover
                        columns={newColumns}
                        data={newData}
                    />
                ) : null}
            </div>
            {newData?.length ? (
                <div className={"mt-3 d-flex align-content-center"}>
                    <button
                        type={"submit"}
                        className={"btn btn-success m-2"}
                        onClick={onOverwriteClicked}
                    >
                        Overwrite
                    </button>
                    <button
                        type={"submit"}
                        className={"btn btn-primary m-2"}
                        onClick={onAddClicked}
                    >
                        Add
                    </button>
                </div>
            ) : (
                <div className={"mt-3 d-flex align-content-center"}>
                    <button
                        type={"submit"}
                        className={"btn btn-outline-danger m-2"}
                        onClick={confirmDelete}
                    >
                        Clear
                    </button>
                </div>
            )}
        </>
    );
};
