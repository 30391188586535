import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import api from "../config/api";

export const fetchParameters = createAsyncThunk('parameters/fetchParameters', async ({token, company_id}) => {
    const response = await api.get(`api/client/global/parameters/${company_id}`, {
        headers: {'Access-Token': token},
    }).catch(err => {
        throw new Error(err.response.data.message[0]?.text);
    });
    return response.data;
})
export const fetchParametersTypes = createAsyncThunk('parameters/fetchParametersTypes', async ({token}) => {
    const response = await api.get(`api/client/global/parameters/types`, {
        headers: {'Access-Token': token},
    }).catch(err => {
        throw new Error(err.response.data.message[0]?.text);
    });
    return response.data;
})
export const updateParameters = createAsyncThunk('parameters/updateParameters', async ({token, company_id, values}) => {
    const response = await api.put(`api/client/global/parameters/${company_id}`, {parameters: values}, {
        headers: {'Access-Token': token},
    }).catch(err => {
        throw new Error(err.response.data.message[0]?.text);
    });
    return response.data;
})

const initialState = {
    parameters: [],
    types: [],
    status: {
        type: 'idle',
        error: null,
    },
    typesStatus: {
        type: 'idle',
        error: null,
    },
}

const parametersSlice = createSlice({
    name: 'parameters',
    initialState,
    reducers: {
        setInitialState: state => initialState,
    },
    extraReducers: {
        [fetchParameters.pending]: (state, action) => {
            state.status.type = 'loading'
        },
        [fetchParameters.fulfilled]: (state, action) => {
            state.status.type = 'succeeded'
            state.parameters = action.payload.data
        },
        [fetchParameters.rejected]: (state, action) => {
            state.status.type = 'failed'
            state.status.error = action.error.message
        },
        [fetchParametersTypes.pending]: (state, action) => {
            state.typesStatus.type = 'loading'
        },
        [fetchParametersTypes.fulfilled]: (state, action) => {
            state.typesStatus.type = 'succeeded'
            state.types = action.payload.data
        },
        [fetchParametersTypes.rejected]: (state, action) => {
            state.typesStatus.type = 'failed'
            state.typesStatus.error = action.error.message
        },
        [updateParameters.pending]: (state, action) => {
            state.status.type = 'loading'
        },
        [updateParameters.fulfilled]: (state, action) => {
            state.status.type = 'succeeded'
            state.parameters = action.payload.data
        },
        [updateParameters.rejected]: (state, action) => {
            state.status.type = 'failed'
            state.status.error = action.error.message
        },
    }
})

export const {setInitialState} = parametersSlice.actions
export default parametersSlice.reducer

export const selectAllParameters = (state) => state.parameters.parameters
export const selectAllParametersTypes = (state) => state.parameters.types
