import {useHistory} from "react-router";
import {useSelector} from "react-redux";
import React from "react";

export default ({children}) => {
    const authSelector = useSelector(state => state.auth)
    let history = useHistory();

    const getPayload = (jwt) => {
        // A JWT has 3 parts separated by '.'
        // The middle part is a base64 encoded JSON
        // decode the base64
        return atob(jwt.split(".")[1])
    }

    if (authSelector.token && authSelector.token.length !== 0) {

        const payload = getPayload(authSelector.token);

        const expiration = new Date(payload.exp);
        const now = new Date();

        const isExpired = expiration.getTime() - now.getTime() < (1000 * 60 * 5);

        if (!isExpired) {
            console.log('TOKEN', authSelector.token)
            console.log('LOGGED IN WITH', authSelector.credential.username)
            return children
        }
    }

    history.push('/login')
    return <div/>
}