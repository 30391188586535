import React, {useState} from 'react'
import {useDispatch, useSelector} from "react-redux"
import {resetPassword} from "../../../../../redux/credentialsSlice";
import StatusWrapper from "../../../../../abstractComponents/StatusWrapper";

const RemoveCredentialComponent = ({credential}) => {
    const dispatch = useDispatch()

    const [status, setStatus] = useState(null)

    const authToken = useSelector((state) => state.auth.token)
    const credentialStatus = useSelector((state) => state.credentials.status)

    const confirmReset = async () => {
        await dispatch(resetPassword({
            token: authToken,
            credentialId: credential.credential_id
        })).then(() => {
            setStatus(null)
        })
    }

    if (status === 'confirm') {
        return <StatusWrapper statusSelector={credentialStatus}>
            <div
                className="alert alert-secondary m-3 d-flex flex-column justify-content-between text-center align-items-center"
                role="alert">
                <i className="fas fa-exclamation-triangle fs-1"/>
                <div>
                    <h4 className="alert-heading">Are you sure?</h4>
                    <p>Is not possible to choose which password will be. It is possible to define it on this same
                        page.</p>
                    <button className={"btn btn-outline-primary me-3"} type={'button'}
                            onClick={() => confirmReset()}><i className={"far fa-trash-alt"}/> Reset current password
                    </button>
                </div>
            </div>
        </StatusWrapper>
    }
    return (
        <div className="alert alert-primary m-3" role="alert">
            <h4 className="alert-heading"><i className="far fa-paper-plane me-2"/>Reset password</h4>
            <p>Send an email or SMS with a new random password.</p>
            <button className={"btn btn-outline-primary me-3"} type={'button'}
                    onClick={() => setStatus('confirm')}>Execute action
            </button>
        </div>
    )
}
export default RemoveCredentialComponent