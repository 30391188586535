import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import api from "../config/api";

export const tryLogin = createAsyncThunk('auth/tryLogin', async (loginData) => {
    const response = await api.post(`api/goliath/auth`, loginData)
    return response.data;
})
export const renewToken = createAsyncThunk('auth/renewToken', async ({token, loginData}) => {
    const response = await api.post(`api/goliath/auth/renew`, loginData, {
        headers: {'Access-Token': token},
    }).catch(err => {
        throw new Error(err.response.data.message[0]?.text);
    });
    return response.data;
})
const initialState = {
    credential: {},
    token: null,
    extra: null,
    status: {
        type: 'idle',
        error: null,
    },
}
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setInitialState: state => initialState,
    },
    extraReducers: {
        [tryLogin.pending]: (state, action) => {
            state.status.type = 'loading'
        },
        [tryLogin.fulfilled]: (state, action) => {
            state.status.type = 'succeeded'
            state.credential = action.payload.data.credential
            state.token = action.payload.data.token
        },
        [tryLogin.rejected]: (state, action) => {
            state.status.type = 'failed'
            state.status.error = action.error.message
        },

        [renewToken.pending]: (state, action) => {
            state.status.type = 'loading'
        },
        [renewToken.fulfilled]: (state, action) => {
            state.status.type = 'succeeded'
            state.credential = action.payload.data.credential
            if (action.payload.data.extra) state.extra = action.payload.data.extra
            state.token = action.payload.data.token
        },
        [renewToken.rejected]: (state, action) => {
            state.status.type = 'failed'
            state.status.error = action.error.message
        },
    }
})

export const {setInitialState} = authSlice.actions
export default authSlice.reducer
