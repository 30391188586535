import React from 'react'
import {useSelector} from "react-redux";

import {fetchCompanies, selectAllCompanies} from "../../../redux/companiesSlice";
import TableComponent from "../../../abstractComponents/TableComponent";
import {NavLink} from "react-router-dom";
import {useRouteMatch} from "react-router";
import moment from "moment";

const CompanyRow = ({company, index}) => {
    let match = useRouteMatch();
    let ms = company.total_count*1000;
    let momentObject = moment.duration(ms);
    let parsedTime = Math.floor(momentObject.asHours()) + moment.utc(ms).format(":mm:ss");
    return <tr key={index}>
        <td>{company.company_id}</td>
        <td>{company.name}</td>
        <td>{company.url}</td>
        <td>{parsedTime}</td>
        <td>{company.total_users}</td>
        <td>{company.limit_indexing ?? 0} h</td>
        <td>
            <NavLink to={`${match.path}update/${company.company_id}`} className="btn btn-info btn-sm"><i
                className="far fa-edit"/>Edit</NavLink>
        </td>
    </tr>
}

const CompaniesList = () => {
    const companies = useSelector(selectAllCompanies)
    const companiesStatus = useSelector(state => state.companies.status)
    const companiesPagination = useSelector((state) => state.companies.pagination)

    return <div className={'card'}>
        <TableComponent columns={['ID', 'Name', 'Url prefix', 'Total duration', 'Total users', 'Indexing Limit', '']}
                        data={companies}
                        fetchData={fetchCompanies}
                        rowsPerPage={10}
                        status={companiesStatus}
                        rowComponent={(row, key) => <CompanyRow key={key} company={row}/>}/>
    </div>
}
export default CompaniesList