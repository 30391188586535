import React, {useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux"
import {fetchCredential, selectSelectedCredential, setInitialState} from "../../../../redux/credentialsSlice";
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import {fetchCompanies, selectAllCompanies} from "../../../../redux/companiesSlice";
import {fetchGroups, selectAllGroups} from "../../../../redux/groupsSlice";
import LoadingTools from "../../../../layout/LoadingTools";
import moment from "moment";
import TabUpdateFormComponent from "./TabUpdateFormComponent";
import TabCompaniesComponent from "./TabCompaniesComponent";
import TabGroupsComponent from "./TabGroupsComponent";
import TabActionsComponent from "./TabActionsComponent";

export default ({match}) => {
    const authToken = useSelector((state) => state.auth.token)
    const credentialSelected = useSelector(selectSelectedCredential)
    const credentialStatus = useSelector((state) => state.credentials.status)

    const companies = useSelector(selectAllCompanies)
    const companyStatus = useSelector((state) => state.companies.status)

    const groups = useSelector(selectAllGroups)
    const groupsStatus = useSelector((state) => state.groups.status)

    const dispatch = useDispatch()
    const currentCredentialId = match.params.credentialId

    useEffect(() => {
        dispatch(setInitialState())
        dispatch(fetchCredential({token: authToken, credentialId: currentCredentialId}))
        dispatch(fetchCompanies({token: authToken}))
        dispatch(fetchGroups({token: authToken}))
    }, [])


    if (!credentialSelected.credential_id && groupsStatus.type === 'loading' && !(credentialSelected.credential_id && credentialStatus.type === 'loading')) {
        return <LoadingTools/>
    }
    return <Tabs>
        <div className={'card'}>
            <div style={{background: 'linear-gradient(to right, #ece9e6, #ffffff)'}}>
                <div className={'card-body row'}>
                    <div className="col-lg-6 d-flex align-items-center">
                        <div className="flex-shrink-0">
                            {credentialSelected.avatar_url ?
                                <img src={credentialSelected.avatar_url} className={'rounded'} alt={'Avatar'}
                                     style={{maxWidth: '110px', maxHeight: '110px'}}/>
                                : null}
                        </div>
                        <div className="flex-grow-1 ms-3">
                            <h3>{credentialSelected.username && credentialSelected.username.length > 1 ? credentialSelected.username :
                                <><i className="text-danger fa fa-times"/> Without username/email</>}</h3>
                            <ul>
                                <li>
                                    <div className={'fw-bold'}>Issuer</div>
                                    <div
                                        className={'badge rounded-pill bg-secondary'}
                                        style={{whiteSpace: "normal"}}>{credentialSelected.external_iss}</div>
                                </li>
                                <li>
                                    <div className={'fw-bold'}>Subject</div>
                                    <div
                                        className={'badge rounded-pill bg-secondary'}
                                        style={{whiteSpace: "normal"}}>{credentialSelected.external_sub}</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={'col-lg-6 row d-flex justify-content-evenly pe-2'}>
                        <div
                            className={'col-sm-3 d-flex flex-column text-center text-light p-1 me-2 mb-2 rounded-3 bg-primary'}>
                            <div>Creation date</div>
                            <div
                                style={{fontSize: '10px'}}>{credentialSelected.created_at ? moment(credentialSelected.created_at).format('L LT') : '-'}</div>
                        </div>
                        <div
                            className={'col-sm-3 d-flex flex-column text-center text-light p-1 me-2 mb-2 rounded-3 bg-primary'}>
                            <div>Last update</div>
                            <div
                                style={{fontSize: '10px'}}>{credentialSelected.updated_at ? moment(credentialSelected.updated_at).format('L LT') : '-'}</div>
                        </div>
                        <div
                            className={'col-sm-3 d-flex flex-column text-center text-light p-1 me-2 mb-2 rounded-3 bg-primary'}>
                            <div>T&C</div>
                            <div
                                style={{fontSize: '10px'}}>{credentialSelected.termsAndConditions ? moment(credentialSelected.termsAndConditions).format('L LT') : '-'}</div>
                        </div>
                        <div
                            className={'col-sm-3 d-flex flex-column text-center text-light p-1 me-2 mb-2 rounded-3 bg-success'}>
                            <div>Last login</div>
                            <div
                                style={{fontSize: '10px'}}>{credentialSelected.last_login ? moment(credentialSelected.last_login).format('L LT') : '-'}</div>
                        </div>
                        <div
                            className={'col-sm-3 d-flex flex-column text-center text-light p-1 me-2 mb-2 rounded-3 bg-success'}>
                            <div>Last login from <i className="fab fa-aws"/></div>
                            <div
                                style={{fontSize: '10px'}}>{credentialSelected.last_cognito_login ? moment(credentialSelected.last_cognito_login).format('L LT') : '-'}</div>
                        </div>
                        <div
                            className={'col-sm-3 d-flex flex-column text-center text-light p-1 me-2 mb-2 rounded-3 bg-danger'}>
                            <div>Deleted at</div>
                            <div
                                style={{fontSize: '10px'}}>{credentialSelected.deleted_at ? moment(credentialSelected.deleted_at).format('L LT') : '-'}</div>
                        </div>
                    </div>
                </div>
                <TabList className={'nav nav-tabs'}>
                    <Tab className={'nav-link btn btn-link'} selectedClassName={'active'}>Basic data</Tab>
                    <Tab className={'nav-link btn btn-link'} selectedClassName={'active'}>Companies</Tab>
                    <Tab className={'nav-link btn btn-link'} selectedClassName={'active'}>Permissions</Tab>
                    <Tab className={'nav-link btn btn-link'} selectedClassName={'active'}>Actions</Tab>
                </TabList>
            </div>
            <TabPanel selected={true}>
                <TabUpdateFormComponent credential={credentialSelected}/>
            </TabPanel>
            <TabPanel>
                <TabCompaniesComponent credential={credentialSelected}/>
            </TabPanel>
            <TabPanel>
                <TabGroupsComponent credential={credentialSelected}/>
            </TabPanel>
            <TabPanel>
                <TabActionsComponent credential={credentialSelected}/>
            </TabPanel>
        </div>
    </Tabs>
}