import React, {useState} from 'react'
import {useDispatch, useSelector} from "react-redux"
import {addNewCredential} from "../../../redux/goliathCredentialsSlice";
import {Field, Form, Formik} from "formik";

import * as generator from "generate-password";
import LoadingTools from "../../../layout/LoadingTools";

export default () => {
    const [status, setStatus] = useState(null)

    const authToken = useSelector((state) => state.auth.token)
    const credentialStatus = useSelector((state) => state.credentials.status)

    const dispatch = useDispatch()

    let initialValues = {
        name: '',
        surname: '',
        username: '',
        avatar_url: '',
        password: generator.generate({
            length: 10,
            numbers: true
        })
    };

    const PrintStatus = () => {
        setTimeout(() => {
            setStatus(null)
        }, 6000)
        switch (status) {
            case 'succeeded':
                return <div className={'ms-3 mt-2 text-success'}><i className="fas fa-check"/> Applied</div>
            case 'loading':
                return <div className={'ms-3 mt-2 text-secondary'}><i className="fas fa-spinner fa-spin"/> Loading...
                </div>
            case 'error':
                return <div className={'ms-3 mt-2 text-danger'}><i className="fas fa-times"/> Error</div>
            default:
                return null
        }
    }

    const onSaveCredentialClicked = async (values, {resetForm}) => {
        try {
            setStatus('loading')
            if (values.password && values.password.length > 0) {
                values.password = btoa(values.password)
            }
            await dispatch(addNewCredential({token: authToken, newCredential: {...values}}))
            setStatus('succeeded')
            initialValues.password = generator.generate({
                length: 10,
                numbers: true
            });
            resetForm()
        } catch (err) {
            console.error('Failed to save the credential: ', err)
            setStatus('error')
        }
    }

    let disabled = credentialStatus.type === 'loading'

    if (credentialStatus.type === 'succeeded' || credentialStatus.type === 'idle') {
        return <div className={'card'}>
            <div className={'card-body'}>
                <div className={"d-flex justify-content-between mb-3"}>
                    <h3 className={'card-title'}>Create new credential</h3>
                </div>
                <Formik initialValues={initialValues} onSubmit={onSaveCredentialClicked}>
                    {({values}) => (
                        <Form>
                            <div className="form-floating mb-3">
                                <Field id={'name'} name={'name'} type={'text'} className={'form-control'}
                                       placeholder={'name'}
                                       disabled={disabled}/>
                                <label htmlFor={'name'}>Name</label>
                            </div>
                            <div className="form-floating mb-3">
                                <Field id={'surname'} name={'surname'} type={'text'} className={'form-control'}
                                       placeholder={'surname'}
                                       disabled={disabled}/>
                                <label htmlFor={'surname'}>Surname</label>
                            </div>
                            <div className="form-floating mb-3">
                                <Field id={'username'} name={'username'} type={'text'} className={'form-control'}
                                       placeholder={'username'}
                                       disabled={disabled}/>
                                <label htmlFor={'username'}>Username (email is recommended)</label>
                            </div>
                            <div className={'form-floating mb-3'}>
                                <Field id={'avatar_url'} name={'avatar_url'} type={'text'} className={'form-control'}
                                       placeholder={'avatar_url'}
                                       disabled={disabled}/>
                                <label htmlFor={'avatar_url'}>Avatar URL</label>
                            </div>
                            {values.avatar_url ?
                                <div className={'mb-3'}>
                                    <img src={values.avatar_url} className={'rounded'} alt={'Avatar'}
                                         style={{maxWidth: '50px', maxHeight: '50px'}}/>
                                </div> : null}
                            {disabled ? <LoadingTools/> : <div className="form-floating mb-3">
                                <Field id={'password'} name={'password'} type={'text'} className={'form-control'}
                                       placeholder={'password'}
                                       readOnly={true}/>
                                <label htmlFor={'password'}>Generated Password</label>
                            </div>}
                            <div className={'mt-3 d-flex align-content-center'}>
                                <button type={'submit'} className={'btn btn-success'}>Save</button>
                                <PrintStatus/>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    }
    if (credentialStatus.type === 'loading') {
        return <LoadingTools/>
    }
    if (credentialStatus.type === 'failed') {
        return <div className={'alert alert-danger'}>{credentialStatus.error}</div>
    }
}