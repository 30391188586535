import React, {useState} from 'react'
import {useDispatch, useSelector} from "react-redux"
import {fetchCredential} from "../../../../redux/credentialsSlice";
import {updateCredentialsCompanies} from "../../../../redux/credentialsCrud";
import Select from "react-select";
import {selectAllCompanies} from "../../../../redux/companiesSlice";
import StatusWrapper from "../../../../abstractComponents/StatusWrapper";

const TabCompaniesComponent = ({credential}) => {
    const dispatch = useDispatch()

    const [status, setStatus] = useState(null)

    const authToken = useSelector((state) => state.auth.token)
    const credentialStatus = useSelector((state) => state.credentials.status)
    const companies = useSelector(selectAllCompanies)

    const onChangeCompanies = async (values, action) => {
        try {
            setStatus('loading')
            const result = await updateCredentialsCompanies({
                token: authToken,
                credentialSelected: credential,
                values,
                action
            })
            if (result.result == "success"){
                dispatch(fetchCredential({ token: authToken, credentialId: credential.credential_id }))
                setStatus('succeeded')
            }
            else {
                setStatus('error')
            }
            // await Notification.Toast.fire({icon: 'success', text: 'Updated'})
            // await Notification.Alert.fire({icon: 'success', text: 'Updated'})
        } catch (err) {
            console.error('Failed to save the credential company: ', err)
            setStatus('error')
        }
    }

    let disabled = credentialStatus.type === 'loading'

    return <StatusWrapper statusSelector={credentialStatus}>
        <div className={'card-body'}>
            <Select getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.company_id}
                    value={credential.companies}
                    isMulti
                    options={companies}
                    disabled={disabled}
                    isLoading={status === 'loading'}
                    onChange={onChangeCompanies}/>
        </div>
    </StatusWrapper>

}
export default TabCompaniesComponent