export default () => {
    return <div className="row flex-xl-nowrap">
        <div className="col-md-3 col-xl-2 bd-sidebar"><h4>Patata Page</h4>
            <ul className="nav flex-column">
                <li className="nav-item">
                    <a className="nav-link active" href="#">Active</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#">Link</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#">Link</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link disabled" href="#" tabIndex="-1" aria-disabled="true">Disabled</a>
                </li>
            </ul>
        </div>
        <main className="col-md-9 col-xl-8 py-md-3 pl-md-5 bd-content" role="main">
            <h4>Patata</h4>
        </main>
    </div>
}