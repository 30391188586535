import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import LoadingTools from "../layout/LoadingTools";

const TableComponent = (props) => {
    const {
        data,
        pagination,
        status,
        rowComponent,
        columns,
        fetchData,
        rowsPerPage = 25,
        maxPagesBySide = 5,
        filter = null
    } = props
    const dispatch = useDispatch()
    const authToken = useSelector((state) => state.auth.token)
    const [search, setSearch] = useState('')
    const [selectedPage, setSelectedPage] = useState(0)
    const [filteredData, setFilteredData] = useState([])
    let serverSideLoading = !!pagination

    useEffect(() => {
        // if (status.type === 'idle') {
        if (serverSideLoading) {
            dispatch(fetchData({token: authToken, page: selectedPage}))
        } else {
            dispatch(fetchData({token: authToken}))
        }
        // }
        // }, [status, dispatch])
    }, [])

    useEffect(() => {
        if (status.type === 'succeeded') {
            if (serverSideLoading) {
                dispatch(fetchData({token: authToken, page: selectedPage}))
            }
        }
    }, [selectedPage])

    useEffect(() => {
        let tmpData = data
        if (filter && typeof filter === 'function') {
            tmpData = data.filter(filter)
        }
        /*Search input*/
        tmpData = tmpData.filter(row => {
            const regex = new RegExp(`${search}`, `i`);
            const rowData = Object.values(row)
            return rowData.some(column => {
                if (typeof column !== 'string') return false;
                return regex.test(column);
            });
        })
        setSelectedPage(0)
        setFilteredData(tmpData)
    }, [data, filter, search])

    let content

    const loadPagination = () => {
        let pages = 0

        if (serverSideLoading && pagination && pagination.pages) {
            pages = pagination.pages
        } else {
            pages = Math.ceil(filteredData.length / rowsPerPage)
        }
        let addPre = false;
        let addPost = false;

        const internalPaginationButtons = () => {
            let {pre, post, somethingBefore, somethingAfter} = false;
            let parsed = [...Array(pages)].map((_, i) => {
                //TODO FIX THIS
                if (0 > (selectedPage - maxPagesBySide)) {
                    somethingBefore = true
                }
                if (pages < (selectedPage + maxPagesBySide)) {
                    somethingAfter = true
                }
                if (i < (selectedPage - maxPagesBySide - (somethingBefore ? maxPagesBySide : 0))) {
                    addPre = true
                    return null;
                }
                if (i > (selectedPage + maxPagesBySide + (somethingAfter ? maxPagesBySide : 0))) {
                    addPost = true
                    return null;
                }
                return (
                    <li key={i} className={'page-item' + (selectedPage === i ? ' disabled' : '')}>
                        <button className="page-link" onClick={() => setSelectedPage(i)}>{i + 1}</button>
                    </li>
                );
            });
            return parsed
        }
        let internal = internalPaginationButtons()

        return (
            <nav className={'ms-3 me-3'}>
                <ul className="pagination">
                    <li className={'page-item' + (selectedPage <= 0 ? ' disabled' : '')}>
                        <button className={'page-link'}
                                disabled={selectedPage <= 0}
                                onClick={() => setSelectedPage(selectedPage - 1)}>Previous
                        </button>
                    </li>
                    {addPre === true ? (
                        <>
                            <li className={'page-item'}>
                                <button className="page-link" onClick={() => setSelectedPage(0)}>1</button>
                            </li>
                            <li className={'page-item disabled'}>
                                <button className={'page-link'} disabled>...</button>
                            </li>
                        </>
                    ) : null}
                    {internal}
                    {addPost === true ? (
                        <>
                            <li className={'page-item disabled'}>
                                <button className={'page-link'} disabled>...</button>
                            </li>
                            <li className={'page-item'}>
                                <button className="page-link"
                                        onClick={() => setSelectedPage(pages - 1)}>{pages}</button>
                            </li>
                        </>
                    ) : null}
                    <li className={'page-item' + ((selectedPage + 1) >= pages ? ' disabled' : '')}>
                        <button
                            className={'page-link'}
                            disabled={(selectedPage + 1) >= pages}
                            onClick={() => setSelectedPage(selectedPage + 1)}>Next
                        </button>
                    </li>
                </ul>
            </nav>
        )
    }

    if (status.type === 'succeeded') {
        let paginatedData = filteredData;
        if (!serverSideLoading) {
            const initial = selectedPage * rowsPerPage;
            paginatedData = filteredData.slice(initial, initial + rowsPerPage)
        }
        content = paginatedData.map((row, key) => rowComponent(row, key))
    } else if (status.type === 'loading') {
        content = <tr className={'text-center'}>
            <th colSpan={columns.length}>
                <LoadingTools/>
            </th>
        </tr>
    } else if (status.type === 'failed') {
        content = <tr className={'table-danger text-center'}>
            <th colSpan={columns.length}>{status.error}</th>
        </tr>
    }
    return <div className={'d-flex flex-column'}>
        <div className={'d-flex justify-content-end'}>
            <div className={'p-2'}>
                <input className="form-control form-control-sm" placeholder={'Search on all columns'}
                       onChange={(e) => setSearch(e.target.value)}/>
            </div>
        </div>
        <div className="table-responsive pt-3">
            <table className="table table-sm table-striped table-hover align-middle">
                <thead>
                <tr>
                    {columns.map((name, key) => <th key={key}>{name}</th>)}
                </tr>
                </thead>
                <tbody>{content}</tbody>
            </table>
        </div>
        {status.type === 'loading' ? null : loadPagination()}
    </div>
}

export default TableComponent