import {NavLink} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {setInitialState} from "../redux/authSlice";
import React from "react";
import SelectDatabase from "./SelectDatabase";

export default () => {
    const {REACT_APP_VERSION, REACT_APP_BUILD} = process.env;
    const dispatch = useDispatch()
    const authToken = useSelector((state) => state.auth.token)
    const authExtra = useSelector(state => state.auth.extra)

    const clearAuthState = async () => {
        await dispatch(setInitialState())
    }
    /*
        <NavLink to="/" exact className="nav-link" activeClassName={'active'}>Home</NavLink>
        <NavLink to="/access" className="nav-link" activeClassName={'active'}>Access</NavLink>
        <NavLink to="/indexer" className="nav-link" activeClassName={'active'}>Indexer</NavLink>
        * */
    return <>
        <header>
            <div className="px-3 py-2 bg-dark text-white">
                <div className="container">
                    <div
                        className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
                        <a href="/"
                           className="d-flex align-items-center my-2 my-lg-0 me-lg-auto text-white text-decoration-none">
                            <img src={"https://omnios.ai/wp-content/uploads//2021/03/logo_winnow.svg"}
                                 alt={"Winnow Admin"} style={{filter: "invert(100%) hue-rotate(190deg)"}}/>
                        </a>

                        <ul className="nav col-12 col-lg-auto my-2 justify-content-center my-md-0 text-small">
                            <li>
                                <NavLink to={`/access/`} exact className="nav-link text-white"
                                         activeClassName={'text-secondary'}>
                                    <i className="fas fa-home bi d-block mx-auto mb-1"/>
                                    Dashboard
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={`/access/companies`} className="nav-link text-white"
                                         activeClassName={'text-secondary'}>
                                    <i className="far fa-building bi d-block mx-auto mb-1"/>
                                    Companies
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={`/access/credentials`} className="nav-link text-white"
                                         activeClassName={'text-secondary'}>
                                    <i className="fas fa-fingerprint bi d-block mx-auto mb-1"/>
                                    Credentials
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={`/goliath`} className="nav-link text-white"
                                         activeClassName={'text-secondary'}>
                                    <i className="fas fa-lock bi d-block mx-auto mb-1"/>
                                    Goliath Management
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="px-3 py-2 border-bottom mb-3">
                <div className="container d-flex flex-wrap justify-content-center">
                    <div className="col-12 col-lg-auto mb-2 mb-lg-0 me-lg-auto" style={{minWidth: 200}}>
                        <SelectDatabase reload/>
                    </div>

                    <div className="text-end">
                        <button type="button" onClick={() => clearAuthState()} className="btn btn-light text-dark me-2">
                            Logout <i className="fas fa-sign-out-alt ms-1"/></button>
                    </div>
                </div>
            </div>
        </header>
    </>
}