import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux"
import {selectSelectedCompany} from "../../../../redux/companiesSlice";
import {Field, Form, Formik} from "formik";
import moment from "moment";
import {
    fetchParameters,
    fetchParametersTypes,
    selectAllParameters,
    selectAllParametersTypes,
    updateParameters
} from "../../../../redux/parametersSlice";
import LoadingTools from "../../../../layout/LoadingTools";

export default (props) => {
    const [status, setStatus] = useState(null)

    const authToken = useSelector((state) => state.auth.token)
    const companySelected = useSelector(selectSelectedCompany)
    const parameters = useSelector(selectAllParameters)
    const parametersTypes = useSelector(selectAllParametersTypes)
    const parametersStatus = useSelector((state) => state.parameters.status)
    const parametersTypesStatus = useSelector((state) => state.parameters.typesStatus)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchParameters({token: authToken, company_id: companySelected.company_id}))
        dispatch(fetchParametersTypes({token: authToken}))
    }, [])

    let printTimeout;
    const PrintStatus = () => {
        if (printTimeout) clearTimeout(printTimeout)
        printTimeout = setTimeout(() => {
            setStatus(null)
        }, 6000)
        switch (status) {
            case 'succeeded':
                return <div className={'ms-3 mt-2 text-success'}><i className="fas fa-check"/> Applied</div>
            case 'loading':
                return <div className={'ms-3 mt-2 text-secondary'}><i className="fas fa-spinner fa-spin"/> Loading...
                </div>
            case 'error':
                return <div className={'ms-3 mt-2 text-danger'}><i className="fas fa-times"/> Error</div>
            default:
                return null
        }
    }

    const onSaveCompanyClicked = async (values) => {
        try {
            setStatus('loading')
            await dispatch(updateParameters({
                token: authToken,
                company_id: companySelected.company_id,
                values: {...values}
            }))
            setStatus('succeeded')
        } catch (err) {
            console.error('Failed to save the credential: ', err)
            setStatus('error')
        }
    }

    let disabled = parametersStatus.type === 'loading'
    if (parametersStatus.type === 'succeeded' && parametersTypesStatus.type === 'succeeded') {
        let preparedInitialValues = {}
        for (let type of parametersTypes) {
            preparedInitialValues[type.name] = '';
        }
        for (let param of parameters) {
            preparedInitialValues[param.type.name] = param.value;
        }
        return (
            <>
                <Formik initialValues={preparedInitialValues} onSubmit={onSaveCompanyClicked}>
                    <Form>
                        <div className={`row`}>
                            {Object.keys(preparedInitialValues)?.map((parameter) => {
                                let currentParameter = parameters.find(param => param.type.name === parameter)
                                let currentParameterType = parametersTypes.find(param => param.name === parameter)
                                return (
                                    <div className={"col-12 col-md-6 col-xl-3 mb-3"}>
                                        <label htmlFor={parameter}>{currentParameterType.onlyAdmins ?
                                            <abbr title="Only active on admin users"><i className="text-warning fas fa-star me-2"/></abbr>
                                            : null}
                                            {parameter}</label>
                                        <Field id={parameter} name={parameter} type={'text'}
                                               className={`form-control${currentParameterType.onlyAdmins ? ` border-warning` : ''}`}
                                               placeholder={'Empty'} disabled={disabled}/>
                                        {currentParameter && currentParameter.updated_at ? (
                                            <span className={'text-muted'}>Last updated <abbr
                                                title={moment(currentParameter.updated_at).format('MMMM Do YYYY, h:mm:ss a')}>
                                        {moment(currentParameter.updated_at).fromNow()}
                                        </abbr></span>
                                        ) : null}
                                    </div>
                                );
                            })}
                        </div>
                        <div className={'mt-3 d-flex align-content-center'}>
                            <button type={'submit'} className={'btn btn-success'}>Save</button>
                            <PrintStatus/>
                        </div>
                    </Form>
                </Formik>
            </>
        )
    } else if (parametersStatus.type === 'loading' || parametersTypesStatus.type === 'loading') {
        return <LoadingTools/>
    } else if (parametersStatus.type === 'failed') {
        return <div className={'alert alert-danger'}>{parametersStatus.error}</div>
    } else if (parametersTypesStatus.type === 'failed') {
        return <div className={'alert alert-danger'}>{parametersTypesStatus.error}</div>
    }
    return <div/>
}