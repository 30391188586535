import React, {useState} from 'react'
import {useDispatch, useSelector} from "react-redux"
import {updateCredential} from "../../../../../redux/credentialsSlice";
import LoadingTools from "../../../../../layout/LoadingTools";
import {Field, Form, Formik} from "formik";
import PasswordChecker from "../../../../../abstractComponents/PasswordChecker";
import StatusWrapper from "../../../../../abstractComponents/StatusWrapper";

const ChangePasswordComponent = ({credential}) => {
    const dispatch = useDispatch()

    const [status, setStatus] = useState(null)

    const authToken = useSelector((state) => state.auth.token)
    const credentialStatus = useSelector((state) => state.credentials.status)

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(.+){8,}$/;
    const passwordRegexText = `^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(.+){8,}$`;
    const validatePassword = values => {
        // if (!values) {
        //     return 'Password required';
        // } else if (values.length < 8) {
        //     return 'Password must contain minimum 8 characters';
        // } else if (!passwordRegex.test(/(.*[a-z])+/)) {
        //     return 'Password must contain at least lowercase character';
        // } else if (!passwordRegex.test(/(.*[A-Z])+/)) {
        //     return 'Password must contains uppercase character';
        // } else if (!passwordRegex.test(/(.*\d)+/)) {
        //     return 'Password must contains at least one number';
        if (!passwordRegex.test(values)) {
            return 'Password doesn\'t fit to strength requirements';
        }
    };

    const onSaveCredentialClicked = async (values) => {
        try {
            setStatus('loading')
            let parsedValues = Object.assign({}, values)
            if (parsedValues.password && parsedValues.password.length > 0) {
                parsedValues.password = btoa(parsedValues.password)
            }
            await dispatch(updateCredential({
                token: authToken,
                updatedCredential: {...credential, ...parsedValues}
            }))
            setStatus('succeeded')

            // await Notification.Toast.fire({icon: 'success', text: 'Updated'})
            // await Notification.Alert.fire({icon: 'success', text: 'Updated'})
        } catch (err) {
            console.error('Failed to save the credential: ', err)
            setStatus('error')
        }
    }

    let disabled = credentialStatus.type === 'loading'

    if (status === 'loading') {
        return <div
            className="alert alert-secondary m-3 p-5 d-flex flex-column justify-content-between text-center align-items-center"
            role="alert"><LoadingTools/>
        </div>
    }
    if (status === 'confirm') {
        return (<StatusWrapper statusSelector={credentialStatus}>
                <div className="alert alert-secondary m-3" role="alert">
                    <h4 className="alert-heading"><i className="fas fa-key me-2"/>Change password</h4>
                    <p>Inserted password will be shown, it's not hiden on this input</p>
                    <Formik initialValues={{password: ''}} onSubmit={onSaveCredentialClicked}>
                        {({values, errors}) => (
                            <Form>
                                <fieldset>
                                    <div className="form-floating mb-3">
                                        <Field id={'password'} name={'password'} type={'text'}
                                               className={'form-control'}
                                               placeholder={'password'} disabled={disabled}
                                               pattern={passwordRegexText}
                                               validate={validatePassword}
                                        />
                                        <label htmlFor={'password'}>Enter the new password</label>
                                        {errors.password ? (
                                            <div className="form-text text-danger">{errors.password}</div>
                                        ) : null}
                                        <div className={'form-text'}>Required strength:
                                            <PasswordChecker password={values.password}/>
                                        </div>
                                    </div>
                                </fieldset>
                                <div className={'mt-3 d-flex align-content-center'}>
                                    <button type={'submit'} disabled={!values.password}
                                            className={'btn btn-success'}>Save
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </StatusWrapper>
        )
    }
    return (
        <div className="alert alert-primary m-3" role="alert">
            <h4 className="alert-heading"><i className="fas fa-key me-2"/>Change password</h4>
            <p>This action will change credential's current password without warn to the end user</p>
            <button className={"btn btn-outline-primary me-3"} type={'button'}
                    onClick={() => setStatus('confirm')}>Execute action
            </button>
        </div>
    )
}
export default ChangePasswordComponent