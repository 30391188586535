import React from "react";
import {Route, Switch} from "react-router";

import CompaniesContainer from "./companies/CompaniesContainer";
import {NavLink} from "react-router-dom";
import CredentialsContainer from "./credentials/CredentialsContainer";
import AccessDashboard from "./AccessDashboard";

export default ({match}) => {
    return <div>
        {/*<nav className="navbar navbar-expand navbar-light bg-light">*/}
        {/*    <div className="container-fluid">*/}
        {/*        <nav className="navbar-nav">*/}
        {/*            <NavLink to={`${match.path}/`} exact className="nav-link"*/}
        {/*                     activeClassName={'active'}>Dashboard</NavLink>*/}
        {/*            <NavLink to={`${match.path}/companies`} className="nav-link" activeClassName={'active'}*/}
        {/*            >Companies</NavLink>*/}
        {/*            <NavLink to={`${match.path}/credentials`} className="nav-link" activeClassName={'active'}*/}
        {/*            >Credentials</NavLink>*/}
        {/*        </nav>*/}
        {/*    </div>*/}
        {/*</nav>*/}
        <Switch>
            <Route path={`${match.path}/companies`} component={CompaniesContainer}/>
            <Route path={`${match.path}/credentials`} component={CredentialsContainer}/>
            <Route exact path={`${match.path}/`} component={AccessDashboard}/>
        </Switch>
    </div>
}