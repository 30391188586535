import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchCompanies, selectAllCompanies} from "../../redux/companiesSlice";
import {fetchCredentials, selectAllCredentials} from "../../redux/goliathCredentialsSlice";
import LoadingTools from "../../layout/LoadingTools";
import {Link} from "react-router-dom";

export default ({match}) => {
    const dispatch = useDispatch()

    const authToken = useSelector((state) => state.auth.token)

    const companies = useSelector(selectAllCompanies)
    const companiesStatus = useSelector(state => state.companies.status)

    const credentials = useSelector(selectAllCredentials)
    const credentialsStatus = useSelector(state => state.credentials.status)

    useEffect(() => {
        dispatch(fetchCompanies({token: authToken}))
        dispatch(fetchCredentials({token: authToken}))
    }, [])

    return (<div>
        <div className="row mb-3">
            <div className="col-xl-3 col-lg-6">
                <div className="card card-inverse card-info">
                    <div className="card-block d-flex justify-content-around text-center align-items-center m-2">
                        <div className="rotate">
                            <i className="fas fa-key fa-3x"/>
                        </div>
                        <div>
                            <p className="m-0 text-uppercase">GOLIATH Credentials</p>
                            {credentialsStatus.type === 'succeeded' ?
                                <p className="display-2">{credentials.length}</p> : <LoadingTools/>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}