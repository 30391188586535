import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchCompany,
    selectSelectedCompany,
    setInitialState,
} from "../../../../redux/companiesSlice";
import LoadingTools from "../../../../layout/LoadingTools";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import CredentialsList from "./CredentialsList";
import UpdateForm from "./UpdateForm";
import ParametersForm from "./ParametersForm";
import NetgraphForm from "./NetgraphForm";
import { CustomInsightsForm } from "./CustomInsightsForm";

export default ({ match, props }) => {
    const authToken = useSelector((state) => state.auth.token);
    const companySelected = useSelector(selectSelectedCompany);
    const companyStatus = useSelector((state) => state.companies.status);

    const dispatch = useDispatch();
    const currentCompanyId = match.params.companyId;

    useEffect(() => {
        dispatch(setInitialState());
        dispatch(
            fetchCompany({ token: authToken, companyId: currentCompanyId })
        );
    }, []);

    if (
        companyStatus.type === "succeeded" ||
        (companySelected.company_id && companyStatus.type === "loading")
    ) {
        return (
            <Tabs>
                <div className={"card"}>
                    <div
                        style={{
                            background:
                                "linear-gradient(to right, #ece9e6, #ffffff)",
                        }}
                    >
                        <div className={"card-body d-flex"}>
                            <h3>{companySelected.name}</h3>
                        </div>
                        <TabList className={"nav nav-tabs"}>
                            <Tab
                                className={"nav-link btn btn-link"}
                                selectedClassName={"active"}
                            >
                                Settings
                            </Tab>
                            <Tab
                                className={"nav-link btn btn-link"}
                                selectedClassName={"active"}
                            >
                                Parameters
                            </Tab>
                            <Tab
                                className={"nav-link btn btn-link"}
                                selectedClassName={"active"}
                            >
                                Users
                            </Tab>
                            <Tab
                                className={"nav-link btn btn-link"}
                                selectedClassName={"active"}
                            >
                                Custom Insights
                            </Tab>
                            <Tab
                                className={"nav-link btn btn-link"}
                                selectedClassName={"active"}
                            >
                                Netgraph Design
                            </Tab>
                        </TabList>
                    </div>
                    <TabPanel>
                        <div className={"card-body"}>
                            <UpdateForm company_id={currentCompanyId} />
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className={"card-body"}>
                            <ParametersForm company_id={currentCompanyId} />
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className={"card-body"}>
                            <CredentialsList company_id={currentCompanyId} />
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className={"card-body"}>
                            <CustomInsightsForm company_id={currentCompanyId} />
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className={"card-body"}>
                            <NetgraphForm company_id={currentCompanyId} />
                        </div>
                    </TabPanel>
                </div>
            </Tabs>
        );
    } else if (companyStatus.type === "loading") {
        return <LoadingTools />;
    } else if (companyStatus.type === "failed") {
        return (
            <div className={"alert alert-danger"}>{companyStatus.error}</div>
        );
    }
    return <div />;
};
