import {
    combineReducers
} from '@reduxjs/toolkit'
import authReducer from '../redux/authSlice'
import companiesReducer from '../redux/companiesSlice'
import credentialsReducer from '../redux/credentialsSlice'
import goliathCredentialsSlice from '../redux/goliathCredentialsSlice'
import groupsReducer from '../redux/groupsSlice'
import connectionsSlice from "../redux/connectionsSlice";
import parametersSlice from "../redux/parametersSlice";
import customInsightsSlice from "../redux/customInsightsSlice";

import {
    applyMiddleware,
    createStore
} from 'redux'
import {
    persistReducer,
    persistStore
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {
    composeWithDevTools
} from 'redux-devtools-extension';
import thunk from "redux-thunk"; // defaults to localStorage for web

const rootReducer = combineReducers({
    auth: authReducer,
    connections: connectionsSlice,
    credentials: credentialsReducer,
    goliathCredentials: goliathCredentialsSlice,
    companies: companiesReducer,
    groups: groupsReducer,
    parameters: parametersSlice,
    customInsights: customInsightsSlice
})

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth'] // or blacklist to exclude specific reducers
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
export default () => {
    let store = createStore(persistedReducer,
        composeWithDevTools(applyMiddleware(thunk)));
    let persistor = persistStore(store)
    return {
        store,
        persistor
    }
}