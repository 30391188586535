import React from "react";

export default () => {
    const {REACT_APP_VERSION, REACT_APP_BUILD} = process.env;
    return (
        <footer className={"mt-5 mb-3 text-muted d-flex align-items-center flex-column"}>
            <ul className="nav d-flex">
                <li className="nav-item">
                    <a className={"nav-link text-secondary"} href="https://omnios.ai/privacy-policy/#cookies_page">
                        Cookies policy
                    </a>
                </li>
                <li className="nav-item">
                    <a className={"nav-link text-secondary"} href="https://omnios.ai/privacy-policy/">
                        Privacy Policy
                    </a>
                </li>
                <li className="nav-item">
                    <a className={"nav-link text-secondary"} href="https://omnios.ai/terms-and-conditions/">
                        Legal Terms
                    </a>
                </li>
            </ul>
            <p className={'text-center text-secondary'}>{REACT_APP_VERSION} - {REACT_APP_BUILD}</p>
        </footer>
    )
}