import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import api from "../config/api";

export const fetchConnections = createAsyncThunk('connections/fetchConnections', async ({token}) => {
    const response = await api.get(`api/goliath/connections/`, {
        headers: {'Access-Token': token}
    }).catch(err => {
        throw new Error(err.response.data.message[0]?.text);
    });
    return response.data;
})

const connectionsSlice = createSlice({
    name: 'connections',
    initialState: {
        connections: [],
        status: {
            type: 'idle',
            error: null,
        },
    },
    reducers: {},
    extraReducers: {
        [fetchConnections.pending]: (state, action) => {
            state.status.type = 'loading'
        },
        [fetchConnections.fulfilled]: (state, action) => {
            state.status.type = 'succeeded'
            state.connections = action.payload.data
        },
        [fetchConnections.rejected]: (state, action) => {
            state.status.type = 'failed'
            state.status.error = action.error.message
        },
    }
})

export const {} = connectionsSlice.actions
export default connectionsSlice.reducer

export const selectAllConnections = (state) => state.connections.connections
